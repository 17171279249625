import { IconArrowRight, IconDatabase, IconSlack } from '@supabase/ui';
import { MouseEventHandler, PropsWithChildren, ReactNode } from 'react';

type Props = {
  className?: string;
  title: string;
  onClick?: MouseEventHandler<HTMLDivElement>;
  icon?: ReactNode;
};

export default function Card({
  className,
  children,
  title,
  onClick,
  icon,
}: PropsWithChildren<Props>) {
  return (
    <div
      onClick={onClick}
      className={
        'relative flex flex-col hover:cursor-pointer bg-white py-4 px-4 shadow rounded-md overflow-hidden ' +
          className ?? ''
      }
    >
      <div className={'flex h-8 mb-1 items-center'}>{icon}</div>
      <div className={'pt-1 text-nowrap text-left text-xl'}>
        <div className={'whitespace-nowrap overflow-hidden text-ellipsis'}>
          {title}
        </div>
        {children}
      </div>
    </div>
  );
}
