import { Maybe, QueryResult } from '../../types.generated';
import DataGrid, { Column, FormatterProps } from 'react-data-grid';
import React from 'react';

export default function QueryResultGrid({
  queryResult,
}: {
  queryResult: QueryResult;
}) {
  const columns: Column<Maybe<string[]>>[] =
    queryResult?.columns?.map((column: string, index) => ({
      formatter: ({ row }: FormatterProps<any>) => {
        return (
          <>
            {typeof row?.[index] === 'object'
              ? JSON.stringify(row?.[index])
              : row?.[index]}
          </>
        );
      },
      key: '' + index,
      name: column,
    })) ?? [];

  return (
    <DataGrid
      components={{
        noRowsFallback: (
          <div className={'col-span-full p-2 text-center'}>No rows</div>
        ),
      }}
      defaultColumnOptions={{
        resizable: true,
      }}
      className={'rdg-font-small'}
      rowHeight={30}
      columns={columns}
      rows={queryResult.rows ?? []}
    />
  );
}
