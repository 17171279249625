import { Button, IconDatabase } from '@supabase/ui';
import { Loading } from '../components/Loading';
import SecondaryHeader from '../components/SecondaryHeader';
import { Input, Select } from '@supabase/ui';
import React from 'react';
import {
  GetSourcesDocument,
  useGetSourceByIdQuery,
} from '../graphql/__generated__/queries';
import {
  useDeleteSourceMutation,
  useUpdateSourceMutation,
} from '../graphql/__generated__/mutations';
import useSubmit from '../hooks/useSubmit';
import { useNavigate, useParams } from 'react-router-dom';
import SourceTypeForm from '../components/SourceTypeForm';

export default function EditSource() {
  const [mutation, { loading: updateLoading }] = useUpdateSourceMutation();
  const [deleteMutation] = useDeleteSourceMutation();

  const { id } = useParams();

  const { data, loading } = useGetSourceByIdQuery({
    variables: {
      id,
    },
  });

  const handleSubmit = useSubmit(
    async ({ name, source_type_id, ...originalConfig }) => {
      const config: { [key: string]: any } = {};

      function getValue(value: string) {
        if (value === 'on') {
          return true;
        }

        if (value === 'off') {
          return false;
        }

        return value;
      }

      //FIXME: this is a hack to get around root_ prefix
      Object.keys(originalConfig).forEach((key) => {
        config[key.replace('root_', '')] = getValue(originalConfig[key]);
      });

      await mutation({
        variables: {
          id,
          input: {
            name,
            source_type_id,
            config,
          },
        },
        refetchQueries: [GetSourcesDocument],
      });
    }
  );

  const navigate = useNavigate();

  if (loading) {
    return <Loading active={loading}>Loading...</Loading>;
  }

  return (
    <form onSubmit={handleSubmit} className={'block'}>
      <SecondaryHeader
        title={'Edit source'}
        icon={<IconDatabase />}
        buttonText={'Save'}
        buttonProps={{
          htmlType: 'submit',
          loading: updateLoading,
        }}
      >
        <div className={'flex justify-end flex-auto'}>
          <Button
            onClick={async () => {
              await deleteMutation({
                variables: {
                  id,
                },
              });
              navigate('/');
            }}
            type={'outline'}
            size={'small'}
            className={'mr-2'}
          >
            Delete
          </Button>
        </div>
      </SecondaryHeader>

      <div className={'flex flex-col justify-center items-center'}>
        <div className={'w-full p-4 max-w-2xl'}>
          <Input
            layout={'vertical'}
            label={'Source name'}
            name={'name'}
            required
            className={'pb-5'}
            defaultValue={data?.source_by_pk?.name}
            value={undefined}
          />
          <Select
            className={'pb-5'}
            layout={'vertical'}
            disabled={true}
            name={'source_type_id'}
            label={'Source type'}
          >
            <Select.Option value={data?.source_by_pk?.source_type.id}>
              {data?.source_by_pk?.source_type.name}
            </Select.Option>
          </Select>
          {data?.source_by_pk?.source_type && (
            <SourceTypeForm
              data={data?.source_by_pk.config}
              schema={data?.source_by_pk?.source_type.config_schema}
            />
          )}
        </div>
      </div>
    </form>
  );
}
