import { Button, Checkbox, IconBox, IconDownload, IconShield, IconTrash, Listbox, Select, Toggle } from '@supabase/ui';
import { Loading } from '../components/Loading';
import React, { useEffect, useState } from 'react';
import {
  useGetDestinationsQuery,
  useGetJobByIdQuery,
} from '../graphql/__generated__/queries';
import {
  useDeleteJobMutation,
  useQuerySourceMutation,
  useUpdateJobMutation,
  useUpdateModelMutation,
} from '../graphql/__generated__/mutations';
import useSubmit from '../hooks/useSubmit';
import { useNavigate, useParams } from 'react-router-dom';
import { isAddon } from "../lib/utils";

export default function EditAddonPush() {
  const { id } = useParams();
  const { data: job, loading } = useGetJobByIdQuery({
    variables: {
      id,
    },
  });
  const { data: destination } = useGetDestinationsQuery();
  const [modelMutation, { loading: modelLoading }] = useUpdateModelMutation();
  const [jobMutation, { loading: jobLoading }] = useUpdateJobMutation();
  const [schedule, setSchedule] = useState(false);
  const [selectedColumns, setSelectedColumns] = useState<string[]>([]);
  const [tableName, setTableName] = useState('')
  const [queryMutation, { loading: queryLoading }] = useQuerySourceMutation();
  const [deleteMutation, { loading: deleteLoading }] = useDeleteJobMutation();
  const navigate = useNavigate()

  useEffect(() => {
    if (job?.job_by_pk) {
      setTableName(job?.job_by_pk?.meta?.tableName ?? '')
      setSelectedColumns(job?.job_by_pk?.meta?.selectedColumns ?? [])
    }
  }, [job]);

  useEffect(() => {
    if (job?.job_by_pk?.model?.source && tableName) {
      setSelectedColumns(columns?.map(x => x?.name ?? ''))
    }
  }, [job, tableName]);

  function buildQuery() {
    return `SELECT ${selectedColumns.join(', ')}
            FROM ${tableName}`
  }

  const handleSubmit = useSubmit(async ({ name, source_id, schedule }) => {
    await modelMutation({
      variables: {
        id: job?.job_by_pk?.model?.id,
        input: {
          name: 'Query',
          source_id: source_id,
          query: buildQuery()
        },
      },
    });

    await jobMutation({
      variables: {
        id,
        input: {
          destination_id: destination?.destination[0]?.id ?? '',
          name,
          model_id: job?.job_by_pk?.model?.id,
          message_template: '',
          schedule,
          meta: {
            tableName,
            selectedColumns
          }
        },
      },
    });

    const { data: queryData } = await queryMutation({
      variables: {
        id: id ?? '',
        query: buildQuery()
      }
    })

    await new Promise((resolve, reject) => {
      google.script.run.withFailureHandler(reject).withSuccessHandler(resolve).syncSheet(id, tableName, queryData?.source_query)
    })
  });

  function removeColumn(name: string) {
    setSelectedColumns(selectedColumns.filter(x => x !== name))
  }

  function addColumn(name: string) {
    setSelectedColumns([...selectedColumns, name].sort())
  }

  if (loading) {
    return <Loading active={loading}>Loading...</Loading>;
  }

  const source_id = job?.job_by_pk?.model.source.id
  const columns = job?.job_by_pk?.model.source?.schema?.find(x => x.name === tableName)?.columns ?? []

  function selectAll(e: any) {
    e.preventDefault();
    const allColumnNames = columns.map(x => x?.name ?? '') ?? []
    if (selectedColumns.length === 0) {
      return setSelectedColumns([...allColumnNames])
    }

    if (selectedColumns.length < allColumnNames.length) {
      return setSelectedColumns([...allColumnNames])
    }

    setSelectedColumns([])
  }

  function onDelete() {
    deleteMutation({
      variables: {
        id: id
      }
    }).then(() => navigate(isAddon() ? '/' : '/addon'))
  }

  return (
    <form onSubmit={handleSubmit} className={'p-4 flex flex-col overflow-auto h-full'}>
      <div className={'flex-auto mb-32 text-left'}>
        <input type={'hidden'} value={source_id} name={'source_id'}/>
        {job?.job_by_pk?.model.source &&
            <Listbox label={"Select table"} size={"small"} value={tableName} onChange={(v) => {
              //@ts-ignore
              setTableName(v)
            }} name={'name'}
                     icon={<IconBox/>} className={"flex flex-col"}>
              {job?.job_by_pk?.model.source?.schema?.map(item => {
                return (
                  <Listbox.Option key={item.name} value={item.name} label={item.name}>
                    {item.name}
                  </Listbox.Option>
                )
              })}
            </Listbox>}
        {!!columns.length &&
            <div className={"flex-col border rounded-2xl text-xs text-neutral-600 p-4 mt-4 text-left flex"}>

                <div className={"pb-2"}>Select columns <a className={"pl-2 mb-2 text-xs underline"} onClick={selectAll}
                                                          href="#">select all</a></div>

              {columns?.map(item => {
                return (
                  <Checkbox onChange={(v) => v.target.checked ? addColumn(v.target.name) : removeColumn(v.target.name)}
                            checked={selectedColumns.indexOf(item?.name ?? '') !== -1} className={"pt-1 text-left"}
                            key={item?.name} value={item?.name} label={item?.name ?? ''}
                            description={item?.type}/>
                )
              })}
            </div>}

        <div className={"border rounded-2xl py-3 px-4 mt-2"}>
          <Toggle checked={schedule} onChange={v => setSchedule(!schedule)} size={"tiny"}
                  className={"text-left"} label={"Auto refresh"}/>
          {schedule && <Select name={'schedule'} label={"Period"} size={"small"}>
              <Select.Option value={'* * * * *'}>Every minute</Select.Option>
              <Select.Option value={'0 * * * *'}>Every hour</Select.Option>
              <Select.Option value={'0 0 * * *'}>Every day</Select.Option>
              <Select.Option value={'0 0 0 * *'}>Every week</Select.Option>
              <Select.Option value={'0 0 0 0 *'}>Every month</Select.Option>
          </Select>}
        </div>

      </div>


      <div className={"absolute w-full p-2 px-4 border-t bg-white bottom-0 left-0 right-0"}>
        <div className={"flex"}>
          <Button
            block={true}
            size={'small'}
            loading={modelLoading || jobLoading}
            icon={<IconDownload/>}
          >
            Import and update
          </Button>
          <Button
            // block={true}
            size={'small'}
            loading={deleteLoading}
            type={"default"}
            className={"ml-2"}
            icon={<IconTrash/>} onClick={onDelete}>
          </Button>
        </div>
        <div className={"flex text-xs text-left py-2 text-neutral-500"}>
          <IconShield className={"h-8 w-8 mr-2"}/>
          <div>We encrypt credentials and never store source data.</div>
        </div>
      </div>
    </form>
  );
}
