import React, { useEffect } from 'react';
import './App.css';
import { Auth } from '@supabase/ui';
import AuthBasic from './components/Auth/AuthBasic';
import { Outlet, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import CreateSource from './pages/CreateSource';
import Destinations from './pages/Destinations';
import CreateDestination from './pages/CreateDestination';
import JobDetails from './pages/JobDetails';
import CreatePush from './pages/CreatePush';
import EditPush from './pages/EditPush';
import EditSource from './pages/EditSource';
import Main from './pages/Main';
import Connected from "./pages/Connected";
import Addon from "./pages/Addon";
import AddonLayout from './layouts/Addon'
import CreateSourceOfType from "./pages/CreateSourceOfType";
import CreateAddonPush from "./pages/CreateAddonPush";
import EditAddonPush from "./pages/EditAddonPush";
import { apiClient } from "./lib/client";
import { addonPath, isAddon } from "./lib/utils";

declare global {
  interface Window {
    posthog: any;
    token?: string;
  }
}

function App() {
  const { user } = Auth.useUser();
  // const { boot } = useIntercom();
  let location = useLocation();
  const navigate = useNavigate();

  function successTokenHandler(token: string) {
    apiClient.auth.setSession(token).then(() => {
      navigate('/addon')
    })
  }

  function failureTokenHandler(error: any) {
    console.log('error', error)
  }

  useEffect(() => {
    if (user) {
      window.posthog.identify(user.id, {
        email: user.email,
      });
      // boot({
      //   createdAt: user.created_at,
      //   email: user.email,
      // });
    } else {
      if (typeof google !== 'undefined') {
        google?.script?.run
          ?.withFailureHandler(failureTokenHandler)
          ?.withSuccessHandler(successTokenHandler)
          ?.getToken();
      }
    }
  }, [location, user]);

  if (typeof google === 'undefined' && !user) {
    return (
      <div className="App bg-neutral-50 h-screen relative">
        <AuthBasic/>
      </div>
    );
  }

  const mainPath = isAddon() ? '/main' : '/'

  return (
    <div className="App bg-white h-screen relative">
      <Routes>
        <Route path="/pushes/:id/*" element={<JobDetails/>}/>
        <Route path="/pushes/:id/edit" element={<EditPush/>}/>
        <Route path="/pushes/create" element={<CreatePush/>}/>
        <Route path="/sources/create" element={<CreateSource/>}/>
        <Route path="/sources/:id" element={<EditSource/>}/>
        <Route path="/destinations" element={<Destinations/>}/>
        <Route path="/connect" element={<Connected/>}/>
        <Route path="/destinations/create" element={<CreateDestination/>}/>
        <Route path={mainPath} element={<Main/>}/>
        <Route element={<AddonLayout><Outlet/></AddonLayout>}>
          <Route path={addonPath()} element={<Addon/>}/>
          <Route path={"/addon/sources/create/:type"} element={<CreateSourceOfType/>}/>
          <Route path={"/addon/sources/:id/pushes/create"} element={<CreateAddonPush/>}/>
          <Route path={"/addon/pushes/:id/edit"} element={<EditAddonPush/>}/>
        </Route>
      </Routes>
    </div>
  );
}

export default App;
