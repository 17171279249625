import {
  Button,
  Checkbox, IconBox, IconDownload, IconShield,
  Listbox,
  Select, Toggle,
} from '@supabase/ui';
import React, { useEffect, useState } from 'react';
import {
  useGetSourceByIdQuery,
} from '../graphql/__generated__/queries';
import {
  useCreateDestinationMutation,
  useCreateJobMutation,
  useCreateModelMutation, useQuerySourceMutation,
} from '../graphql/__generated__/mutations';
import useSubmit from '../hooks/useSubmit';
import { useNavigate, useParams } from 'react-router-dom';
import { Loading } from "../components/Loading";

export default function CreateAddonPush() {
  const [tableName, setTableName] = useState<string>('');
  const [selectedColumns, setSelectedColumns] = useState<string[]>([]);
  const [schedule, setSchedule] = useState(false);
  const [destinationMutation] = useCreateDestinationMutation();
  const [modelMutation, { loading: modelLoading }] = useCreateModelMutation();
  const [jobMutation, { loading: jobLoading }] = useCreateJobMutation();
  const [queryMutation, { loading: queryLoading }] = useQuerySourceMutation();

  const navigate = useNavigate();

  const { id: source_id } = useParams<{ id: string }>();

  const { data, loading } = useGetSourceByIdQuery({
    variables: {
      id: source_id
    }
  });

  function buildQuery() {
    return `SELECT ${selectedColumns.join(', ')}
            FROM ${tableName}`
  }

  const handleSubmit = useSubmit(
    async ({ active, schedule }) => {
      const query = buildQuery()

      const { data } = await modelMutation({
        variables: {
          input: {
            name: tableName,
            source_id: source_id,
            query: query
          },
        },
      });

      const { data: destination } = await destinationMutation({
        variables: {
          input: {
            name: 'Sheets ' + data?.insert_model_one?.id,
            config: {
              // access_token
            }
          }
        }
      })

      const { data: jobData } = await jobMutation({
        variables: {
          input: {
            destination_id: destination?.insert_destination_one?.id ?? '',
            name: tableName,
            model_id: data?.insert_model_one?.id,
            message_template: '',
            schedule: schedule ?? '',
            meta: {
              // slack_channel: channel,
              tableName,
              selectedColumns
            },
            active: !!parseInt(active),
          },
        },
      });

      const { data: queryData } = await queryMutation({
        variables: {
          id: source_id ?? '',
          query: query
        }
      })

      await new Promise((resolve, reject) => {
        google.script.run.withFailureHandler(reject).withSuccessHandler(resolve).syncSheet(jobData?.insert_job_one?.id, tableName, queryData?.source_query)
      })

      navigate('/addon/pushes/' + jobData?.insert_job_one?.id + '/edit');
    }
  );

  const columns = data?.source_by_pk?.schema?.find(x => x.name === tableName)?.columns ?? []

  useEffect(() => {
    if (data?.source_by_pk && !tableName) {
      setTableName(data?.source_by_pk?.schema?.[0]?.name ?? '')
    }
    if (data?.source_by_pk && tableName) {
      setSelectedColumns(columns?.map(x => x?.name ?? ''))
    }
  }, [tableName, data]);

  function removeColumn(name: string) {
    selectedColumns.splice(selectedColumns.indexOf(name), 1)
    setSelectedColumns([...selectedColumns])
  }

  function addColumn(name: string) {
    setSelectedColumns([...selectedColumns, name].sort())
  }

  function selectAll(e: any) {
    e.preventDefault();
    const allColumnNames = columns.map(x => x?.name ?? '') ?? []
    if (selectedColumns.length === 0) {
      return setSelectedColumns([...allColumnNames])
    }

    if (selectedColumns.length < allColumnNames.length) {
      return setSelectedColumns([...allColumnNames])
    }

    setSelectedColumns([])
  }

  if (loading) {
    return <Loading active={loading}>Loading...</Loading>
  }

  return (
    <form onSubmit={handleSubmit} className={'p-4 flex flex-col overflow-auto h-full'}>
      <div className={'flex-auto mb-32'}>
        <input type={'hidden'} value={source_id} name={'source_id'}/>
        {data?.source_by_pk &&
            <Listbox label={"Select table"} size={"small"} value={tableName} onChange={(v) => {
              // @ts-ignore
              setTableName(v)
            }} name={'name'}
                     icon={<IconBox/>} className={"flex flex-col"}>
              {data?.source_by_pk?.schema?.map(item => {
                return (
                  <Listbox.Option key={item.name} value={item.name} label={item.name}>
                    {item.name}
                  </Listbox.Option>
                )
              })}
            </Listbox>}
        {!!columns.length &&
            <div className={"flex-col border rounded-2xl text-xs text-neutral-600 p-4 mt-4 text-left flex"}>

                <div className={"pb-2"}>Select columns <a className={"pl-2 mb-2 text-xs underline"} onClick={selectAll} href="#">select all</a></div>



              {columns?.map(item => {
                return (
                  <Checkbox onChange={(v) => v.target.checked ? addColumn(v.target.name) : removeColumn(v.target.name)}
                            checked={selectedColumns.indexOf(item?.name ?? '') !== -1} className={"pt-1 text-left"}
                            key={item?.name} value={item?.name} label={item?.name ?? ''}
                            description={item?.type}/>
                )
              })}
            </div>}

          <div className={"border rounded-2xl py-3 px-4 mt-2"}>
            <Toggle checked={schedule} onChange={v => setSchedule(!schedule)} size={"tiny"}
                    className={"text-left"} label={"Auto refresh"}/>
            {schedule && <Select name={'schedule'} label={"Period"} size={"small"}>
                <Select.Option value={'* * * * *'}>Every minute</Select.Option>
                <Select.Option value={'0 * * * *'}>Every hour</Select.Option>
                <Select.Option value={'0 0 * * *'}>Every day</Select.Option>
                <Select.Option value={'0 0 0 * *'}>Every week</Select.Option>
                <Select.Option value={'0 0 0 0 *'}>Every month</Select.Option>
            </Select>}
        </div>
        <input type={'hidden'} name={'active'}/>
      </div>

      <div className={"absolute p-4 py-3 border-t bg-white bottom-0 left-0 right-0"}>
        <Button
          loading={modelLoading || jobLoading || queryLoading}
          size={'small'}
          block={true}
          icon={<IconDownload />}
        >
          Import and run
        </Button>
        <div className={"flex text-xs text-left py-2 text-neutral-500"}>
          <IconShield className={"h-8 w-8 mr-2"}/>
          <div>We encrypt credentials and never store source data.</div>
        </div>
      </div>
    </form>
  );
}
