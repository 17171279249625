import {
  useGetSlackChannelsQuery,
  useGetSlackInstallUrlQuery,
} from '../../graphql/__generated__/queries';
import React, { useEffect, useState } from 'react';
import { useSlackSendMessageMutation } from '../../graphql/__generated__/mutations';
import nunjucks from 'nunjucks';
import {
  Button,
  IconHash,
  IconSend,
  Input,
  Listbox,
  IconSlack,
} from '@supabase/ui';
import { toast } from 'react-toastify';
import { Loading } from '../Loading';

export default function SlackDestination({
                                           slackChannelId,
                                           slackMessage,
                                           params,
                                           onChange,
                                         }: {
  slackChannelId?: string;
  slackMessage?: string;
  params: { [key: string]: any };
  onChange?: (channel_id?: string, message?: string) => void;
}) {
  const { data, loading, refetch } = useGetSlackChannelsQuery();
  const { data: slackData } = useGetSlackInstallUrlQuery();
  const [mutation, { loading: messageLoading }] = useSlackSendMessageMutation();
  const [connecting, setConnecting] = useState(false);

  async function refetchOnFocus() {
    if (document.visibilityState === 'visible') {
      await refetch();
      setConnecting(false)
    }
  }

  useEffect(() => {
    if (!slackChannelId && data?.slack_channels?.length) {
      onChange?.(data.slack_channels[0].id, slackMessage);
    }

    window.document.addEventListener('visibilitychange', refetchOnFocus)

    return () => window.document.addEventListener('visibilitychange', refetchOnFocus)

  }, [data, slackChannelId]);

  function onConnectClick() {
    setConnecting(true);
    window.open(slackData?.slack_install_url?.install_url ?? '_blank', 'toolbar=no,status=no,menubar=no,location=center,scrollbars=no,resizable=no,height=500,width=657');
  }

  async function handleSendTestMessage() {
    await mutation({
      variables: {
        channel: slackChannelId ?? '',
        message: nunjucks.renderString(slackMessage ?? '', params),
      },
    });
    toast.success(`Message sent successfully.`);
  }

  return (
    <Loading active={loading}>
      <Input.TextArea
        name={'message_template'}
        placeholder={
          'Type your Slack text message here. Use double braces to insert variables, like this: {{data[0].user_name}}'
        }
        className={"font-['Monaco'] slack"}
        rows={6}
        onChange={(e) => onChange?.(slackChannelId, e.target.value)}
        value={slackMessage}
      />
      <div className={'flex justify-between pt-3'}>
        {!data?.slack_channels?.length && (
          <Button
            loading={connecting}
            onClick={onConnectClick}
            icon={<IconSlack/>}
            size={'medium'}
            block
            type={'outline'}
          >
            Connect Slack
          </Button>
        )}
        {!!data?.slack_channels?.length && (
          <>
            <Listbox
              name={'slack_channel'}
              required
              value={slackChannelId ?? undefined}
              placeholder={'Select channel'}
              icon={<IconHash/>}
              onChange={(e: any) => onChange?.(e, slackMessage)}
              className={'flex-auto pr-2'}
            >
              {data?.slack_channels?.map(({ name, id }, index) => (
                <Listbox.Option label={name} key={id} value={id}>
                  {name}
                </Listbox.Option>
              ))}
            </Listbox>
            <input
              type={'hidden'}
              name={'slack_channel'}
              value={slackChannelId}
            />
            <div>
              <Button
                disabled={!slackChannelId}
                icon={<IconSend/>}
                type={'outline'}
                size={'medium'}
                loading={messageLoading}
                onClick={handleSendTestMessage}
              >
                Send test message
              </Button>
              <Button
                className={'ml-1'}
                loading={connecting}
                onClick={onConnectClick}
                icon={<IconSlack/>}
                size={'small'}
                type={'outline'}
              ></Button>
            </div>
          </>
        )}
      </div>
    </Loading>
  );
}
