import {
  IconArrowRight,
  IconCalendar,
  IconClock,
  IconDatabase,
  IconSlack,
  Toggle,
} from '@supabase/ui';
import SecondaryHeader from '../components/SecondaryHeader';
import { useNavigate } from 'react-router-dom';
import {
  GetJobsDocument,
  useGetJobsQuery,
  useGetSourcesQuery,
} from '../graphql/__generated__/queries';
import { useUpdateJobMutation } from '../graphql/__generated__/mutations';
import { greeting, humanizeCron, timeAgo } from '../lib/utils';
import Card from '../components/Card';
import { Postgresql } from '@icons-pack/react-simple-icons';
import { Loading } from '../components/Loading';

export default function Main() {
  const { data: jobsData, loading } = useGetJobsQuery();
  const { data: sourceData, loading: sourceLoading } = useGetSourcesQuery();
  const [mutation] = useUpdateJobMutation();

  const navigate = useNavigate();

  if (!window.localStorage.getItem('pushsql.onboarded')) {
    window.localStorage.setItem('pushsql.onboarded', 'true');
    navigate('/pushes/create');
  }

  async function toggleActive(e: any, jobId: string, active: boolean) {
    e.preventDefault();
    e.stopPropagation();
    await mutation({
      variables: {
        id: jobId,
        input: {
          active: !active,
        },
      },
      refetchQueries: [GetJobsDocument],
    });
  }

  return (
    <>
      <SecondaryHeader
        title={'Home'}
        showButton={true}
        buttonText={'Create push'}
        buttonProps={{
          onClick: () => navigate('/pushes/create'),
        }}
      />
      <Loading active={loading || sourceLoading}>
        <div
          className={
            'flex flex-auto p-4 justify-center text-left bg-neutral-50'
          }
        >
          <div className={'grid gap-4 grid-cols-3 md:w-4/5 2lg:w-3/4 w-full'}>
            <div className={'col-span-3 text-2xl'}>
              <div className={'flex py-4'}>
                <div className={'-mr-1'}>
                  <img
                    alt={'Robot'}
                    src={'/images/robot.svg'}
                    width={'60'}
                    height={'60'}
                  />
                </div>
                <div
                  className={
                    'mt-8 border-8 border-neutral-200 border-b-transparent border-l-transparent border-t-transparent h-0 w-0'
                  }
                ></div>
                <div
                  className={
                    'rounded-xl bg-neutral-200 px-6 pt-4 font-mono text-lg'
                  }
                >
                  {greeting()}
                </div>
              </div>
              <div className={'-mb-3 pt-1'}>Your pushes</div>
            </div>
            {jobsData?.job?.map((item) => (
              <Card
                title={item.name}
                onClick={() => navigate('pushes/' + item.id)}
                key={item.id}
                icon={
                  <>
                    <Postgresql className="h-6 w-6" aria-hidden="true" />
                    <IconArrowRight
                      className="h-5 w-3 mx-1"
                      aria-hidden="true"
                    />
                    <IconSlack className="h-6 w-6" aria-hidden="true" />
                  </>
                }
              >
                <div className={'pt-0 flex'}>
                  <div className={'pr-1 pt-1 text-xs'}>
                    <IconCalendar className={'w-4 inline-flex mr-1'} />
                    {'runs ' + humanizeCron(item.schedule)}
                    <IconClock className={'w-4 inline-flex ml-2 mr-1'} />
                    {'created ' + timeAgo(new Date(item?.created_at ?? ''))}
                  </div>
                </div>
                <div className={'absolute top-5 right-4 flex flex-row'}>
                  <Toggle
                    onClick={(e) => toggleActive(e, item.id, item.active)}
                    checked={item.active}
                  />
                </div>
              </Card>
            ))}
            <Card
              title={'Create push'}
              onClick={() => navigate('/pushes/create')}
              className="bg-neutral-100 hover:bg-neutral-200"
              icon={
                <>
                  <IconDatabase className="h-6 w-6" aria-hidden="true" />
                  <IconArrowRight className="h-6 w-3 mx-1" aria-hidden="true" />
                  <IconSlack className="h-6 w-6" aria-hidden="true" />
                </>
              }
            />
            <div className={'col-span-3 text-2xl'}>
              <div className={'-mb-3 pt-1'}>Your data sources</div>
            </div>
            {sourceData?.source.map((item) => (
              <Card
                title={item.name}
                onClick={() => navigate('sources/' + item.id)}
                key={item.id}
                icon={<Postgresql className="h-6 w-6" aria-hidden="true" />}
              />
            ))}
            <Card
              title={'Connect data source'}
              icon={<IconDatabase className="h-6 w-6" aria-hidden="true" />}
              onClick={() => navigate('/sources/create')}
              className="bg-neutral-100 hover:bg-neutral-200"
            />
          </div>
        </div>
      </Loading>
    </>
  );
}
