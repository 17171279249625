import { useNavigate } from 'react-router-dom';
import { Postgresql, Salesforce, Snowflake } from '@icons-pack/react-simple-icons';
import React from "react";
import { useCreateSampleSourceMutation } from "../graphql/__generated__/mutations";
import { IconDatabase } from "@supabase/ui";
import { useGetJobsQuery } from "../graphql/__generated__/queries";
import { getSourceIcon, timeAgo } from "../lib/utils";
import { Loading } from "../components/Loading";

export default function Addon() {

  const navigate = useNavigate();

  const [createSampleMutation] = useCreateSampleSourceMutation()
  const { data, loading } = useGetJobsQuery()

  function createSampleDatabase(e: any) {
    e.preventDefault()
    createSampleMutation().then(res => {
      navigate('/addon/sources/' + res.data?.create_sample_source?.id + '/pushes/create');
    })
  }

  const sourceItems = [{
    Icon: Snowflake,
    text: 'Snowflake',
    url: '/addon/sources/create/snowflake'
  }, {
    Icon: Postgresql,
    text: 'PostgreSQL',
    url: '/addon/sources/create/postgresql'
  }, {
    Icon: Salesforce,
    text: 'Salesforce',
    url: '/addon/sources/create/salesforce'
  }, {
    Icon: IconDatabase,
    text: 'Sample Database',
    url: '#',
    onClick: createSampleDatabase
  }]

  return (
    <>
      <div className={"text-xs text-neutral-600 pb-2 px-4 pt-4 text-left flex"}>
        Choose a system to import data from
      </div>
      <div className={"flex-col px-2 w-full"}>
        {sourceItems.map(item => (
          <div onClick={item.onClick ? item.onClick : () => navigate(item.url)}
               className={"flex bg-neutral-100 hover:bg-neutral-200 hover:cursor-pointer rounded-2xl font-medium text-sm py-3.5 px-4 mx-2 mb-2"}>
            <item.Icon size={20} className={"mr-2 text-brand-500"}/>
            {item.text}
          </div>
        ))}
      </div>
      {!!data?.job.length &&
          <Loading active={loading}>
              <div className={"text-xs text-neutral-600 pb-2 pt-4 px-4 text-left flex"}>
                  Existing imports
              </div>
              <div className={"flex-col px-2 w-full text-left"}>
                {data?.job.map(item => {
                  const Icon = getSourceIcon(item?.model?.source)
                  return (
                    <div onClick={() => navigate('/addon/pushes/' + item.id + '/edit')}
                         className={"flex-col text-white bg-brand-500 hover:cursor-pointer hover:bg-brand-600 bg-white rounded-2xl text-sm font-medium p-4 mx-2 mb-2"}>
                      <div className={"flex pb-2"}>
                        <Icon size={24} className={"mr-2"}/>
                        <div>{item.name}</div>
                      </div>
                      <span
                        className={"text-xs font-normal border border-brand-100 text-brand-100 rounded-xl p-1 px-2.5"}>created {timeAgo(item.created_at)}</span>
                    </div>
                  )
                })}
              </div>
          </Loading>}
    </>
  );
}
