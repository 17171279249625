import { IconDatabase, Listbox } from '@supabase/ui';
import SecondaryHeader from '../components/SecondaryHeader';
import { Input } from '@supabase/ui';
import React, { useEffect, useState } from 'react';
import {
  GetSourcesDocument,
  useGetSourceTypesQuery,
} from '../graphql/__generated__/queries';
import { useCreateSourceMutation } from '../graphql/__generated__/mutations';
import useSubmit from '../hooks/useSubmit';
import { useNavigate } from 'react-router-dom';
import { Loading } from '../components/Loading';
import SourceTypeForm from '../components/SourceTypeForm';

type Props = {
  includeHeader?: boolean
  type?: string
}

export default function CreateSource({ includeHeader = true, type = undefined }: Props) {
  const [mutation] = useCreateSourceMutation();

  const { data, loading } = useGetSourceTypesQuery();

  const [schemaType, setSchemaType] = useState<any>();

  const navigate = useNavigate();

  const handleSubmit = useSubmit(
    async ({ name, source_type_id, ...originalConfig }) => {
      const config: { [key: string]: any } = {};

      function getValue(value: string) {
        if (value === 'on') {
          return true;
        }

        if (value === 'off') {
          return false;
        }

        return value;
      }

      //FIXME: this is a hack to get around root_ prefix
      Object.keys(originalConfig).forEach((key) => {
        config[key.replace('root_', '')] = getValue(originalConfig[key]);
      });

      await mutation({
        variables: {
          input: {
            name,
            source_type_id: schemaType?.id,
            config,
          },
        },
        refetchQueries: [GetSourcesDocument],
      });

      navigate(-1);
    }
  );

  useEffect(() => {
    if (type) {
      setSchemaType(data?.source_type.find((x) => {
        return x?.name?.toLowerCase() === type
      }));
    } else {
      setSchemaType(data?.source_type[0]);
    }
  }, [data, type]);

  if (loading) {
    return <Loading active={loading}>Loading...</Loading>;
  }

  return (
    <form onSubmit={handleSubmit} className={'block'}>
      {includeHeader && <SecondaryHeader
          title={'Create source'}
          icon={<IconDatabase/>}
          buttonText={'Create'}
          buttonProps={{
            htmlType: 'submit',
          }}
      />}

      <div
        className={'flex bg-neutral-50 flex-col justify-center items-center h-full'}
      >
        <div className={'w-full p-4 max-w-2xl h-full'}>
          <div
            className="p-2 mb-4 bg-yellow-100 rounded-lg dark:bg-yellow-200"
            role="alert"
          >
            <div className="mt-2 mb-2 text-sm text-yellow-700 dark:text-yellow-800">
              Allow queries from PushSQL's IP address <b>34.172.84.217</b>.
            </div>
          </div>
          <Input
            layout={'vertical'}
            label={'Source name'}
            name={'name'}
            required
            className={'pb-5'}
          />
          {!type && <Listbox
            label={'Source type'}
            value={schemaType?.id}
            className={'pb-5'}
            onChange={(e) => {
              setSchemaType(data?.source_type.find((x) => x.id === e));
            }}
          >
            {data?.source_type?.map((type) => (
              <Listbox.Option label={type.name} value={type.id} key={type.id}>
                {type.name}
              </Listbox.Option>
            ))}
          </Listbox>}

          {schemaType && <SourceTypeForm schema={schemaType.config_schema}/>}
        </div>
      </div>
    </form>
  );
}
