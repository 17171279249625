import { Button, IconArrowRight } from '@supabase/ui';
import {
  GetDestinationsDocument,
  useGetDestinationsQuery,
} from '../graphql/__generated__/queries';
import DataGrid, { FormatterProps } from 'react-data-grid';
import { useNavigate } from 'react-router-dom';
import SecondaryHeader from '../components/SecondaryHeader';
import { useDeleteDestinationMutation } from '../graphql/__generated__/mutations';
import { Loading } from '../components/Loading';

export default function Destinations() {
  const { loading, data } = useGetDestinationsQuery();
  const [del, { loading: deleting }] = useDeleteDestinationMutation();

  const columns = [
    { key: 'name', name: 'Name' },
    {
      key: 'action',
      width: 100,
      formatter: ({ row }: FormatterProps<any>) => (
        <Button
          danger={true}
          loading={deleting}
          onClick={() =>
            del({
              variables: { id: row.id },
              refetchQueries: [GetDestinationsDocument],
            })
          }
        >
          Delete
        </Button>
      ),
      name: 'Action',
    },
  ];

  const navigate = useNavigate();

  return (
    <>
      <SecondaryHeader
        icon={<IconArrowRight />}
        title={'Destinations'}
        buttonProps={{
          onClick: () => navigate('/destinations/create'),
        }}
      />
      <Loading active={loading}>
        <div className={'flex-auto flex justify-center'}>
          <div className={'w-full xl:w-2/3'}>
            <DataGrid
              rowHeight={46}
              columns={columns}
              rows={data?.destination ?? []}
            />
          </div>
        </div>
      </Loading>
    </>
  );
}
