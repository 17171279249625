import { Input, Toggle } from '@supabase/ui';
import SchemaForm, { Widget } from '@rjsf/core';
import React, { useState } from 'react';
import { capitalize } from '../../lib/utils';
import { JSONSchema7 } from 'json-schema';

const widgets: { [name: string]: Widget } = {
  TextWidget: (props) => {
    return (
      <Input
        label={capitalize(props.label)}
        onChange={props.onChange}
        name={props.id || props.label}
        value={undefined}
        defaultValue={props.value}
        size={"small"}
        type={props.label.toLowerCase() === 'password' ? 'password' : 'text'}
        required
      />
    );
  },
  CheckboxWidget: (props) => {
    const parsedVal =
      props.value === 'true' || props.value === true || props.value === 1;

    const [value, setValue] = useState(parsedVal);

    return (
      <>
        <Toggle
          // id={props.id}
          // name={props.id || props.label}
          checked={value}
          label={capitalize(props.label).toUpperCase()}
          onChange={() => {
            props.onChange(!value);
            setValue(!value);
          }}
          size={"small"}
          layout={'vertical'}
        />
        <input
          name={props.id}
          id={props.id}
          checked={value}
          type={'checkbox'}
          className={'hidden'}
        />
      </>
    );
  },
};

const generateUiSchema = (selectedSourceSchema: any) => {
  const uiSchema: { [key: string]: any } = {
    'ui:submitButtonOptions': {
      norender: true,
    },
  };
  Object.keys(selectedSourceSchema.properties).forEach((x) => {
    uiSchema[x] = {
      'ui:options': {
        label: false,
      },
    };
  });

  return uiSchema;
};

export default function SourceTypeForm({
  schema,
  data,
}: {
  schema: JSONSchema7;
  data?: any;
}) {
  return (
    <SchemaForm
      tagName={'div'}
      formData={data}
      name={'config'}
      schema={schema}
      widgets={widgets}
      uiSchema={generateUiSchema(schema)}
    >
      <div />
    </SchemaForm>
  );
}
