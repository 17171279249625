import * as Types from '../../types.generated';

import { gql } from '@apollo/client';
import {
  SourceTypeFragmentFragmentDoc,
  JobFragmentFragmentDoc,
  SourceFragmentFragmentDoc,
  ModelFragmentFragmentDoc,
} from './fragments';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  jsonb: any;
  timestamptz: any;
  uuid: any;
};

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type Boolean_Comparison_Exp = {
  _eq: InputMaybe<Scalars['Boolean']>;
  _gt: InputMaybe<Scalars['Boolean']>;
  _gte: InputMaybe<Scalars['Boolean']>;
  _in: InputMaybe<Array<Scalars['Boolean']>>;
  _is_null: InputMaybe<Scalars['Boolean']>;
  _lt: InputMaybe<Scalars['Boolean']>;
  _lte: InputMaybe<Scalars['Boolean']>;
  _neq: InputMaybe<Scalars['Boolean']>;
  _nin: InputMaybe<Array<Scalars['Boolean']>>;
};

export type CancelRunsResponse = {
  __typename?: 'CancelRunsResponse';
  success: Maybe<Scalars['Boolean']>;
};

export type Column = {
  __typename?: 'Column';
  default: Maybe<Scalars['String']>;
  name: Scalars['String'];
  nullable: Scalars['Boolean'];
  type: Scalars['String'];
};

export type CreateSampleSourceResponse = {
  __typename?: 'CreateSampleSourceResponse';
  id: Maybe<Scalars['String']>;
};

export type GoogleSheet = {
  __typename?: 'GoogleSheet';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type GoogleSheetWriteResponse = {
  __typename?: 'GoogleSheetWriteResponse';
  ok: Maybe<Scalars['Boolean']>;
};

export type JobRun = {
  __typename?: 'JobRun';
  created_at: Maybe<Scalars['String']>;
  id: Maybe<Scalars['String']>;
  next_retry_at: Maybe<Scalars['String']>;
  scheduled_time: Maybe<Scalars['String']>;
  status: Maybe<Scalars['String']>;
  tries: Maybe<Scalars['Int']>;
};

export type QueryResult = {
  __typename?: 'QueryResult';
  columns: Maybe<Array<Scalars['String']>>;
  rows: Maybe<Array<Maybe<Array<Scalars['String']>>>>;
};

export type ScheduleRunsResponse = {
  __typename?: 'ScheduleRunsResponse';
  success: Maybe<Scalars['Boolean']>;
};

export type SendSlackMessageResponse = {
  __typename?: 'SendSlackMessageResponse';
  ok: Maybe<Scalars['Boolean']>;
};

export type SlackChannel = {
  __typename?: 'SlackChannel';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type SlackInstallUrlResponse = {
  __typename?: 'SlackInstallUrlResponse';
  install_url: Scalars['String'];
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq: InputMaybe<Scalars['String']>;
  _gt: InputMaybe<Scalars['String']>;
  _gte: InputMaybe<Scalars['String']>;
  /** does the column match the given case-insensitive pattern */
  _ilike: InputMaybe<Scalars['String']>;
  _in: InputMaybe<Array<Scalars['String']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex: InputMaybe<Scalars['String']>;
  _is_null: InputMaybe<Scalars['Boolean']>;
  /** does the column match the given pattern */
  _like: InputMaybe<Scalars['String']>;
  _lt: InputMaybe<Scalars['String']>;
  _lte: InputMaybe<Scalars['String']>;
  _neq: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike: InputMaybe<Scalars['String']>;
  _nin: InputMaybe<Array<Scalars['String']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given pattern */
  _nlike: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar: InputMaybe<Scalars['String']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex: InputMaybe<Scalars['String']>;
  /** does the column match the given SQL regular expression */
  _similar: InputMaybe<Scalars['String']>;
};

export type Suggestion = {
  __typename?: 'Suggestion';
  query: Scalars['String'];
};

export type Table = {
  __typename?: 'Table';
  columns: Array<Maybe<Column>>;
  name: Scalars['String'];
};

/** columns and relationships of "destination" */
export type Destination = {
  __typename?: 'destination';
  config: Scalars['jsonb'];
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  name: Scalars['String'];
  user_id: Scalars['uuid'];
};

/** columns and relationships of "destination" */
export type DestinationConfigArgs = {
  path: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "destination" */
export type Destination_Aggregate = {
  __typename?: 'destination_aggregate';
  aggregate: Maybe<Destination_Aggregate_Fields>;
  nodes: Array<Destination>;
};

/** aggregate fields of "destination" */
export type Destination_Aggregate_Fields = {
  __typename?: 'destination_aggregate_fields';
  count: Scalars['Int'];
  max: Maybe<Destination_Max_Fields>;
  min: Maybe<Destination_Min_Fields>;
};

/** aggregate fields of "destination" */
export type Destination_Aggregate_FieldsCountArgs = {
  columns: InputMaybe<Array<Destination_Select_Column>>;
  distinct: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Destination_Append_Input = {
  config: InputMaybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "destination". All fields are combined with a logical 'AND'. */
export type Destination_Bool_Exp = {
  _and: InputMaybe<Array<Destination_Bool_Exp>>;
  _not: InputMaybe<Destination_Bool_Exp>;
  _or: InputMaybe<Array<Destination_Bool_Exp>>;
  config: InputMaybe<Jsonb_Comparison_Exp>;
  created_at: InputMaybe<Timestamptz_Comparison_Exp>;
  id: InputMaybe<Uuid_Comparison_Exp>;
  name: InputMaybe<String_Comparison_Exp>;
  user_id: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "destination" */
export enum Destination_Constraint {
  /** unique or primary key constraint on columns "id" */
  DestinationPkey = 'destination_pkey',
  /** unique or primary key constraint on columns "user_id" */
  DestinationUserIdKey = 'destination_user_id_key',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Destination_Delete_At_Path_Input = {
  config: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Destination_Delete_Elem_Input = {
  config: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Destination_Delete_Key_Input = {
  config: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "destination" */
export type Destination_Insert_Input = {
  config: InputMaybe<Scalars['jsonb']>;
  created_at: InputMaybe<Scalars['timestamptz']>;
  id: InputMaybe<Scalars['uuid']>;
  name: InputMaybe<Scalars['String']>;
  user_id: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Destination_Max_Fields = {
  __typename?: 'destination_max_fields';
  created_at: Maybe<Scalars['timestamptz']>;
  id: Maybe<Scalars['uuid']>;
  name: Maybe<Scalars['String']>;
  user_id: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Destination_Min_Fields = {
  __typename?: 'destination_min_fields';
  created_at: Maybe<Scalars['timestamptz']>;
  id: Maybe<Scalars['uuid']>;
  name: Maybe<Scalars['String']>;
  user_id: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "destination" */
export type Destination_Mutation_Response = {
  __typename?: 'destination_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Destination>;
};

/** input type for inserting object relation for remote table "destination" */
export type Destination_Obj_Rel_Insert_Input = {
  data: Destination_Insert_Input;
  /** upsert condition */
  on_conflict: InputMaybe<Destination_On_Conflict>;
};

/** on_conflict condition type for table "destination" */
export type Destination_On_Conflict = {
  constraint: Destination_Constraint;
  update_columns: Array<Destination_Update_Column>;
  where: InputMaybe<Destination_Bool_Exp>;
};

/** Ordering options when selecting data from "destination". */
export type Destination_Order_By = {
  config: InputMaybe<Order_By>;
  created_at: InputMaybe<Order_By>;
  id: InputMaybe<Order_By>;
  name: InputMaybe<Order_By>;
  user_id: InputMaybe<Order_By>;
};

/** primary key columns input for table: destination */
export type Destination_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Destination_Prepend_Input = {
  config: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "destination" */
export enum Destination_Select_Column {
  /** column name */
  Config = 'config',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UserId = 'user_id',
}

/** input type for updating data in table "destination" */
export type Destination_Set_Input = {
  config: InputMaybe<Scalars['jsonb']>;
  created_at: InputMaybe<Scalars['timestamptz']>;
  id: InputMaybe<Scalars['uuid']>;
  name: InputMaybe<Scalars['String']>;
  user_id: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "destination" */
export enum Destination_Update_Column {
  /** column name */
  Config = 'config',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UserId = 'user_id',
}

/** columns and relationships of "job" */
export type Job = {
  __typename?: 'job';
  active: Scalars['Boolean'];
  created_at: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  destination: Destination;
  destination_id: Scalars['uuid'];
  id: Scalars['uuid'];
  job_runs: Maybe<Array<JobRun>>;
  message_template: Scalars['jsonb'];
  meta: Scalars['jsonb'];
  /** An object relationship */
  model: Model;
  model_id: Scalars['uuid'];
  name: Scalars['String'];
  schedule: Scalars['String'];
  user_id: Scalars['uuid'];
};

/** columns and relationships of "job" */
export type JobMessage_TemplateArgs = {
  path: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "job" */
export type JobMetaArgs = {
  path: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "job" */
export type Job_Aggregate = {
  __typename?: 'job_aggregate';
  aggregate: Maybe<Job_Aggregate_Fields>;
  nodes: Array<Job>;
};

/** aggregate fields of "job" */
export type Job_Aggregate_Fields = {
  __typename?: 'job_aggregate_fields';
  count: Scalars['Int'];
  max: Maybe<Job_Max_Fields>;
  min: Maybe<Job_Min_Fields>;
};

/** aggregate fields of "job" */
export type Job_Aggregate_FieldsCountArgs = {
  columns: InputMaybe<Array<Job_Select_Column>>;
  distinct: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Job_Append_Input = {
  message_template: InputMaybe<Scalars['jsonb']>;
  meta: InputMaybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "job". All fields are combined with a logical 'AND'. */
export type Job_Bool_Exp = {
  _and: InputMaybe<Array<Job_Bool_Exp>>;
  _not: InputMaybe<Job_Bool_Exp>;
  _or: InputMaybe<Array<Job_Bool_Exp>>;
  active: InputMaybe<Boolean_Comparison_Exp>;
  created_at: InputMaybe<Timestamptz_Comparison_Exp>;
  destination: InputMaybe<Destination_Bool_Exp>;
  destination_id: InputMaybe<Uuid_Comparison_Exp>;
  id: InputMaybe<Uuid_Comparison_Exp>;
  message_template: InputMaybe<Jsonb_Comparison_Exp>;
  meta: InputMaybe<Jsonb_Comparison_Exp>;
  model: InputMaybe<Model_Bool_Exp>;
  model_id: InputMaybe<Uuid_Comparison_Exp>;
  name: InputMaybe<String_Comparison_Exp>;
  schedule: InputMaybe<String_Comparison_Exp>;
  user_id: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "job" */
export enum Job_Constraint {
  /** unique or primary key constraint on columns "id" */
  JobPkey = 'job_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Job_Delete_At_Path_Input = {
  message_template: InputMaybe<Array<Scalars['String']>>;
  meta: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Job_Delete_Elem_Input = {
  message_template: InputMaybe<Scalars['Int']>;
  meta: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Job_Delete_Key_Input = {
  message_template: InputMaybe<Scalars['String']>;
  meta: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "job" */
export type Job_Insert_Input = {
  active: InputMaybe<Scalars['Boolean']>;
  created_at: InputMaybe<Scalars['timestamptz']>;
  destination: InputMaybe<Destination_Obj_Rel_Insert_Input>;
  destination_id: InputMaybe<Scalars['uuid']>;
  id: InputMaybe<Scalars['uuid']>;
  message_template: InputMaybe<Scalars['jsonb']>;
  meta: InputMaybe<Scalars['jsonb']>;
  model: InputMaybe<Model_Obj_Rel_Insert_Input>;
  model_id: InputMaybe<Scalars['uuid']>;
  name: InputMaybe<Scalars['String']>;
  schedule: InputMaybe<Scalars['String']>;
  user_id: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Job_Max_Fields = {
  __typename?: 'job_max_fields';
  created_at: Maybe<Scalars['timestamptz']>;
  destination_id: Maybe<Scalars['uuid']>;
  id: Maybe<Scalars['uuid']>;
  model_id: Maybe<Scalars['uuid']>;
  name: Maybe<Scalars['String']>;
  schedule: Maybe<Scalars['String']>;
  user_id: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Job_Min_Fields = {
  __typename?: 'job_min_fields';
  created_at: Maybe<Scalars['timestamptz']>;
  destination_id: Maybe<Scalars['uuid']>;
  id: Maybe<Scalars['uuid']>;
  model_id: Maybe<Scalars['uuid']>;
  name: Maybe<Scalars['String']>;
  schedule: Maybe<Scalars['String']>;
  user_id: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "job" */
export type Job_Mutation_Response = {
  __typename?: 'job_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Job>;
};

/** on_conflict condition type for table "job" */
export type Job_On_Conflict = {
  constraint: Job_Constraint;
  update_columns: Array<Job_Update_Column>;
  where: InputMaybe<Job_Bool_Exp>;
};

/** Ordering options when selecting data from "job". */
export type Job_Order_By = {
  active: InputMaybe<Order_By>;
  created_at: InputMaybe<Order_By>;
  destination: InputMaybe<Destination_Order_By>;
  destination_id: InputMaybe<Order_By>;
  id: InputMaybe<Order_By>;
  message_template: InputMaybe<Order_By>;
  meta: InputMaybe<Order_By>;
  model: InputMaybe<Model_Order_By>;
  model_id: InputMaybe<Order_By>;
  name: InputMaybe<Order_By>;
  schedule: InputMaybe<Order_By>;
  user_id: InputMaybe<Order_By>;
};

/** primary key columns input for table: job */
export type Job_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Job_Prepend_Input = {
  message_template: InputMaybe<Scalars['jsonb']>;
  meta: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "job" */
export enum Job_Select_Column {
  /** column name */
  Active = 'active',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DestinationId = 'destination_id',
  /** column name */
  Id = 'id',
  /** column name */
  MessageTemplate = 'message_template',
  /** column name */
  Meta = 'meta',
  /** column name */
  ModelId = 'model_id',
  /** column name */
  Name = 'name',
  /** column name */
  Schedule = 'schedule',
  /** column name */
  UserId = 'user_id',
}

/** input type for updating data in table "job" */
export type Job_Set_Input = {
  active: InputMaybe<Scalars['Boolean']>;
  created_at: InputMaybe<Scalars['timestamptz']>;
  destination_id: InputMaybe<Scalars['uuid']>;
  id: InputMaybe<Scalars['uuid']>;
  message_template: InputMaybe<Scalars['jsonb']>;
  meta: InputMaybe<Scalars['jsonb']>;
  model_id: InputMaybe<Scalars['uuid']>;
  name: InputMaybe<Scalars['String']>;
  schedule: InputMaybe<Scalars['String']>;
  user_id: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "job" */
export enum Job_Update_Column {
  /** column name */
  Active = 'active',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DestinationId = 'destination_id',
  /** column name */
  Id = 'id',
  /** column name */
  MessageTemplate = 'message_template',
  /** column name */
  Meta = 'meta',
  /** column name */
  ModelId = 'model_id',
  /** column name */
  Name = 'name',
  /** column name */
  Schedule = 'schedule',
  /** column name */
  UserId = 'user_id',
}

export type Jsonb_Cast_Exp = {
  String: InputMaybe<String_Comparison_Exp>;
};

/** Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'. */
export type Jsonb_Comparison_Exp = {
  _cast: InputMaybe<Jsonb_Cast_Exp>;
  /** is the column contained in the given json value */
  _contained_in: InputMaybe<Scalars['jsonb']>;
  /** does the column contain the given json value at the top level */
  _contains: InputMaybe<Scalars['jsonb']>;
  _eq: InputMaybe<Scalars['jsonb']>;
  _gt: InputMaybe<Scalars['jsonb']>;
  _gte: InputMaybe<Scalars['jsonb']>;
  /** does the string exist as a top-level key in the column */
  _has_key: InputMaybe<Scalars['String']>;
  /** do all of these strings exist as top-level keys in the column */
  _has_keys_all: InputMaybe<Array<Scalars['String']>>;
  /** do any of these strings exist as top-level keys in the column */
  _has_keys_any: InputMaybe<Array<Scalars['String']>>;
  _in: InputMaybe<Array<Scalars['jsonb']>>;
  _is_null: InputMaybe<Scalars['Boolean']>;
  _lt: InputMaybe<Scalars['jsonb']>;
  _lte: InputMaybe<Scalars['jsonb']>;
  _neq: InputMaybe<Scalars['jsonb']>;
  _nin: InputMaybe<Array<Scalars['jsonb']>>;
};

/** columns and relationships of "model" */
export type Model = {
  __typename?: 'model';
  created_at: Maybe<Scalars['timestamptz']>;
  id: Scalars['uuid'];
  name: Scalars['String'];
  query: Scalars['String'];
  /** An object relationship */
  source: Source;
  source_id: Scalars['uuid'];
  user_id: Scalars['uuid'];
};

/** aggregated selection of "model" */
export type Model_Aggregate = {
  __typename?: 'model_aggregate';
  aggregate: Maybe<Model_Aggregate_Fields>;
  nodes: Array<Model>;
};

/** aggregate fields of "model" */
export type Model_Aggregate_Fields = {
  __typename?: 'model_aggregate_fields';
  count: Scalars['Int'];
  max: Maybe<Model_Max_Fields>;
  min: Maybe<Model_Min_Fields>;
};

/** aggregate fields of "model" */
export type Model_Aggregate_FieldsCountArgs = {
  columns: InputMaybe<Array<Model_Select_Column>>;
  distinct: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "model". All fields are combined with a logical 'AND'. */
export type Model_Bool_Exp = {
  _and: InputMaybe<Array<Model_Bool_Exp>>;
  _not: InputMaybe<Model_Bool_Exp>;
  _or: InputMaybe<Array<Model_Bool_Exp>>;
  created_at: InputMaybe<Timestamptz_Comparison_Exp>;
  id: InputMaybe<Uuid_Comparison_Exp>;
  name: InputMaybe<String_Comparison_Exp>;
  query: InputMaybe<String_Comparison_Exp>;
  source: InputMaybe<Source_Bool_Exp>;
  source_id: InputMaybe<Uuid_Comparison_Exp>;
  user_id: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "model" */
export enum Model_Constraint {
  /** unique or primary key constraint on columns "id" */
  ModelPkey = 'model_pkey',
}

/** input type for inserting data into table "model" */
export type Model_Insert_Input = {
  created_at: InputMaybe<Scalars['timestamptz']>;
  id: InputMaybe<Scalars['uuid']>;
  name: InputMaybe<Scalars['String']>;
  query: InputMaybe<Scalars['String']>;
  source: InputMaybe<Source_Obj_Rel_Insert_Input>;
  source_id: InputMaybe<Scalars['uuid']>;
  user_id: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Model_Max_Fields = {
  __typename?: 'model_max_fields';
  created_at: Maybe<Scalars['timestamptz']>;
  id: Maybe<Scalars['uuid']>;
  name: Maybe<Scalars['String']>;
  query: Maybe<Scalars['String']>;
  source_id: Maybe<Scalars['uuid']>;
  user_id: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Model_Min_Fields = {
  __typename?: 'model_min_fields';
  created_at: Maybe<Scalars['timestamptz']>;
  id: Maybe<Scalars['uuid']>;
  name: Maybe<Scalars['String']>;
  query: Maybe<Scalars['String']>;
  source_id: Maybe<Scalars['uuid']>;
  user_id: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "model" */
export type Model_Mutation_Response = {
  __typename?: 'model_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Model>;
};

/** input type for inserting object relation for remote table "model" */
export type Model_Obj_Rel_Insert_Input = {
  data: Model_Insert_Input;
  /** upsert condition */
  on_conflict: InputMaybe<Model_On_Conflict>;
};

/** on_conflict condition type for table "model" */
export type Model_On_Conflict = {
  constraint: Model_Constraint;
  update_columns: Array<Model_Update_Column>;
  where: InputMaybe<Model_Bool_Exp>;
};

/** Ordering options when selecting data from "model". */
export type Model_Order_By = {
  created_at: InputMaybe<Order_By>;
  id: InputMaybe<Order_By>;
  name: InputMaybe<Order_By>;
  query: InputMaybe<Order_By>;
  source: InputMaybe<Source_Order_By>;
  source_id: InputMaybe<Order_By>;
  user_id: InputMaybe<Order_By>;
};

/** primary key columns input for table: model */
export type Model_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "model" */
export enum Model_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Query = 'query',
  /** column name */
  SourceId = 'source_id',
  /** column name */
  UserId = 'user_id',
}

/** input type for updating data in table "model" */
export type Model_Set_Input = {
  created_at: InputMaybe<Scalars['timestamptz']>;
  id: InputMaybe<Scalars['uuid']>;
  name: InputMaybe<Scalars['String']>;
  query: InputMaybe<Scalars['String']>;
  source_id: InputMaybe<Scalars['uuid']>;
  user_id: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "model" */
export enum Model_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Query = 'query',
  /** column name */
  SourceId = 'source_id',
  /** column name */
  UserId = 'user_id',
}

/** mutation root */
export type Mutation_Root = {
  __typename?: 'mutation_root';
  cancel_runs: Maybe<CancelRunsResponse>;
  create_sample_source: Maybe<CreateSampleSourceResponse>;
  /** delete data from the table: "destination" */
  delete_destination: Maybe<Destination_Mutation_Response>;
  /** delete single row from the table: "destination" */
  delete_destination_by_pk: Maybe<Destination>;
  /** delete data from the table: "job" */
  delete_job: Maybe<Job_Mutation_Response>;
  /** delete single row from the table: "job" */
  delete_job_by_pk: Maybe<Job>;
  /** delete data from the table: "model" */
  delete_model: Maybe<Model_Mutation_Response>;
  /** delete single row from the table: "model" */
  delete_model_by_pk: Maybe<Model>;
  /** delete data from the table: "source" */
  delete_source: Maybe<Source_Mutation_Response>;
  /** delete single row from the table: "source" */
  delete_source_by_pk: Maybe<Source>;
  /** delete data from the table: "source_type" */
  delete_source_type: Maybe<Source_Type_Mutation_Response>;
  /** delete single row from the table: "source_type" */
  delete_source_type_by_pk: Maybe<Source_Type>;
  google_sheets_write: Maybe<GoogleSheetWriteResponse>;
  /** insert data into the table: "destination" */
  insert_destination: Maybe<Destination_Mutation_Response>;
  /** insert a single row into the table: "destination" */
  insert_destination_one: Maybe<Destination>;
  /** insert data into the table: "job" */
  insert_job: Maybe<Job_Mutation_Response>;
  /** insert a single row into the table: "job" */
  insert_job_one: Maybe<Job>;
  /** insert data into the table: "model" */
  insert_model: Maybe<Model_Mutation_Response>;
  /** insert a single row into the table: "model" */
  insert_model_one: Maybe<Model>;
  /** insert data into the table: "source" */
  insert_source: Maybe<Source_Mutation_Response>;
  /** insert a single row into the table: "source" */
  insert_source_one: Maybe<Source>;
  /** insert data into the table: "source_type" */
  insert_source_type: Maybe<Source_Type_Mutation_Response>;
  /** insert a single row into the table: "source_type" */
  insert_source_type_one: Maybe<Source_Type>;
  schedule_runs: Maybe<ScheduleRunsResponse>;
  slack_send_message: Maybe<SendSlackMessageResponse>;
  source_query: Maybe<QueryResult>;
  source_suggestions: Maybe<Array<Suggestion>>;
  /** update data of the table: "destination" */
  update_destination: Maybe<Destination_Mutation_Response>;
  /** update single row of the table: "destination" */
  update_destination_by_pk: Maybe<Destination>;
  /** update data of the table: "job" */
  update_job: Maybe<Job_Mutation_Response>;
  /** update single row of the table: "job" */
  update_job_by_pk: Maybe<Job>;
  /** update data of the table: "model" */
  update_model: Maybe<Model_Mutation_Response>;
  /** update single row of the table: "model" */
  update_model_by_pk: Maybe<Model>;
  /** update data of the table: "source" */
  update_source: Maybe<Source_Mutation_Response>;
  /** update single row of the table: "source" */
  update_source_by_pk: Maybe<Source>;
  /** update data of the table: "source_type" */
  update_source_type: Maybe<Source_Type_Mutation_Response>;
  /** update single row of the table: "source_type" */
  update_source_type_by_pk: Maybe<Source_Type>;
};

/** mutation root */
export type Mutation_RootCancel_RunsArgs = {
  job_id: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_DestinationArgs = {
  where: Destination_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Destination_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_JobArgs = {
  where: Job_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Job_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_ModelArgs = {
  where: Model_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Model_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_SourceArgs = {
  where: Source_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Source_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Source_TypeArgs = {
  where: Source_Type_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Source_Type_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootGoogle_Sheets_WriteArgs = {
  data: InputMaybe<Array<InputMaybe<Array<InputMaybe<Scalars['String']>>>>>;
  spreadsheet_id: Scalars['String'];
};

/** mutation root */
export type Mutation_RootInsert_DestinationArgs = {
  objects: Array<Destination_Insert_Input>;
  on_conflict: InputMaybe<Destination_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Destination_OneArgs = {
  object: Destination_Insert_Input;
  on_conflict: InputMaybe<Destination_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_JobArgs = {
  objects: Array<Job_Insert_Input>;
  on_conflict: InputMaybe<Job_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Job_OneArgs = {
  object: Job_Insert_Input;
  on_conflict: InputMaybe<Job_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_ModelArgs = {
  objects: Array<Model_Insert_Input>;
  on_conflict: InputMaybe<Model_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Model_OneArgs = {
  object: Model_Insert_Input;
  on_conflict: InputMaybe<Model_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_SourceArgs = {
  objects: Array<Source_Insert_Input>;
  on_conflict: InputMaybe<Source_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Source_OneArgs = {
  object: Source_Insert_Input;
  on_conflict: InputMaybe<Source_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Source_TypeArgs = {
  objects: Array<Source_Type_Insert_Input>;
  on_conflict: InputMaybe<Source_Type_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Source_Type_OneArgs = {
  object: Source_Type_Insert_Input;
  on_conflict: InputMaybe<Source_Type_On_Conflict>;
};

/** mutation root */
export type Mutation_RootSchedule_RunsArgs = {
  job_id: Scalars['String'];
};

/** mutation root */
export type Mutation_RootSlack_Send_MessageArgs = {
  channel: Scalars['String'];
  message: Scalars['String'];
};

/** mutation root */
export type Mutation_RootSource_QueryArgs = {
  query: Scalars['String'];
  source_id: Scalars['String'];
};

/** mutation root */
export type Mutation_RootSource_SuggestionsArgs = {
  source_id: Scalars['String'];
};

/** mutation root */
export type Mutation_RootUpdate_DestinationArgs = {
  _append: InputMaybe<Destination_Append_Input>;
  _delete_at_path: InputMaybe<Destination_Delete_At_Path_Input>;
  _delete_elem: InputMaybe<Destination_Delete_Elem_Input>;
  _delete_key: InputMaybe<Destination_Delete_Key_Input>;
  _prepend: InputMaybe<Destination_Prepend_Input>;
  _set: InputMaybe<Destination_Set_Input>;
  where: Destination_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Destination_By_PkArgs = {
  _append: InputMaybe<Destination_Append_Input>;
  _delete_at_path: InputMaybe<Destination_Delete_At_Path_Input>;
  _delete_elem: InputMaybe<Destination_Delete_Elem_Input>;
  _delete_key: InputMaybe<Destination_Delete_Key_Input>;
  _prepend: InputMaybe<Destination_Prepend_Input>;
  _set: InputMaybe<Destination_Set_Input>;
  pk_columns: Destination_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_JobArgs = {
  _append: InputMaybe<Job_Append_Input>;
  _delete_at_path: InputMaybe<Job_Delete_At_Path_Input>;
  _delete_elem: InputMaybe<Job_Delete_Elem_Input>;
  _delete_key: InputMaybe<Job_Delete_Key_Input>;
  _prepend: InputMaybe<Job_Prepend_Input>;
  _set: InputMaybe<Job_Set_Input>;
  where: Job_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Job_By_PkArgs = {
  _append: InputMaybe<Job_Append_Input>;
  _delete_at_path: InputMaybe<Job_Delete_At_Path_Input>;
  _delete_elem: InputMaybe<Job_Delete_Elem_Input>;
  _delete_key: InputMaybe<Job_Delete_Key_Input>;
  _prepend: InputMaybe<Job_Prepend_Input>;
  _set: InputMaybe<Job_Set_Input>;
  pk_columns: Job_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_ModelArgs = {
  _set: InputMaybe<Model_Set_Input>;
  where: Model_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Model_By_PkArgs = {
  _set: InputMaybe<Model_Set_Input>;
  pk_columns: Model_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_SourceArgs = {
  _append: InputMaybe<Source_Append_Input>;
  _delete_at_path: InputMaybe<Source_Delete_At_Path_Input>;
  _delete_elem: InputMaybe<Source_Delete_Elem_Input>;
  _delete_key: InputMaybe<Source_Delete_Key_Input>;
  _prepend: InputMaybe<Source_Prepend_Input>;
  _set: InputMaybe<Source_Set_Input>;
  where: Source_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Source_By_PkArgs = {
  _append: InputMaybe<Source_Append_Input>;
  _delete_at_path: InputMaybe<Source_Delete_At_Path_Input>;
  _delete_elem: InputMaybe<Source_Delete_Elem_Input>;
  _delete_key: InputMaybe<Source_Delete_Key_Input>;
  _prepend: InputMaybe<Source_Prepend_Input>;
  _set: InputMaybe<Source_Set_Input>;
  pk_columns: Source_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Source_TypeArgs = {
  _append: InputMaybe<Source_Type_Append_Input>;
  _delete_at_path: InputMaybe<Source_Type_Delete_At_Path_Input>;
  _delete_elem: InputMaybe<Source_Type_Delete_Elem_Input>;
  _delete_key: InputMaybe<Source_Type_Delete_Key_Input>;
  _prepend: InputMaybe<Source_Type_Prepend_Input>;
  _set: InputMaybe<Source_Type_Set_Input>;
  where: Source_Type_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Source_Type_By_PkArgs = {
  _append: InputMaybe<Source_Type_Append_Input>;
  _delete_at_path: InputMaybe<Source_Type_Delete_At_Path_Input>;
  _delete_elem: InputMaybe<Source_Type_Delete_Elem_Input>;
  _delete_key: InputMaybe<Source_Type_Delete_Key_Input>;
  _prepend: InputMaybe<Source_Type_Prepend_Input>;
  _set: InputMaybe<Source_Type_Set_Input>;
  pk_columns: Source_Type_Pk_Columns_Input;
};

/** column ordering options */
export enum Order_By {
  /** in ascending order, nulls last */
  Asc = 'asc',
  /** in ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in descending order, nulls first */
  Desc = 'desc',
  /** in descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in descending order, nulls last */
  DescNullsLast = 'desc_nulls_last',
}

export type Query_Root = {
  __typename?: 'query_root';
  /** fetch data from the table: "destination" */
  destination: Array<Destination>;
  /** fetch aggregated fields from the table: "destination" */
  destination_aggregate: Destination_Aggregate;
  /** fetch data from the table: "destination" using primary key columns */
  destination_by_pk: Maybe<Destination>;
  google_sheets: Maybe<Array<GoogleSheet>>;
  /** fetch data from the table: "job" */
  job: Array<Job>;
  /** fetch aggregated fields from the table: "job" */
  job_aggregate: Job_Aggregate;
  /** fetch data from the table: "job" using primary key columns */
  job_by_pk: Maybe<Job>;
  job_runs: Maybe<Array<JobRun>>;
  /** fetch data from the table: "model" */
  model: Array<Model>;
  /** fetch aggregated fields from the table: "model" */
  model_aggregate: Model_Aggregate;
  /** fetch data from the table: "model" using primary key columns */
  model_by_pk: Maybe<Model>;
  model_sample: Maybe<QueryResult>;
  slack_channels: Maybe<Array<SlackChannel>>;
  slack_install_url: Maybe<SlackInstallUrlResponse>;
  /** fetch data from the table: "source" */
  source: Array<Source>;
  /** fetch aggregated fields from the table: "source" */
  source_aggregate: Source_Aggregate;
  /** fetch data from the table: "source" using primary key columns */
  source_by_pk: Maybe<Source>;
  source_schema: Maybe<Array<Table>>;
  /** fetch data from the table: "source_type" */
  source_type: Array<Source_Type>;
  /** fetch aggregated fields from the table: "source_type" */
  source_type_aggregate: Source_Type_Aggregate;
  /** fetch data from the table: "source_type" using primary key columns */
  source_type_by_pk: Maybe<Source_Type>;
};

export type Query_RootDestinationArgs = {
  distinct_on: InputMaybe<Array<Destination_Select_Column>>;
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
  order_by: InputMaybe<Array<Destination_Order_By>>;
  where: InputMaybe<Destination_Bool_Exp>;
};

export type Query_RootDestination_AggregateArgs = {
  distinct_on: InputMaybe<Array<Destination_Select_Column>>;
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
  order_by: InputMaybe<Array<Destination_Order_By>>;
  where: InputMaybe<Destination_Bool_Exp>;
};

export type Query_RootDestination_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootJobArgs = {
  distinct_on: InputMaybe<Array<Job_Select_Column>>;
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
  order_by: InputMaybe<Array<Job_Order_By>>;
  where: InputMaybe<Job_Bool_Exp>;
};

export type Query_RootJob_AggregateArgs = {
  distinct_on: InputMaybe<Array<Job_Select_Column>>;
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
  order_by: InputMaybe<Array<Job_Order_By>>;
  where: InputMaybe<Job_Bool_Exp>;
};

export type Query_RootJob_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootJob_RunsArgs = {
  job_id: Scalars['String'];
};

export type Query_RootModelArgs = {
  distinct_on: InputMaybe<Array<Model_Select_Column>>;
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
  order_by: InputMaybe<Array<Model_Order_By>>;
  where: InputMaybe<Model_Bool_Exp>;
};

export type Query_RootModel_AggregateArgs = {
  distinct_on: InputMaybe<Array<Model_Select_Column>>;
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
  order_by: InputMaybe<Array<Model_Order_By>>;
  where: InputMaybe<Model_Bool_Exp>;
};

export type Query_RootModel_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootModel_SampleArgs = {
  model_id: Scalars['String'];
};

export type Query_RootSourceArgs = {
  distinct_on: InputMaybe<Array<Source_Select_Column>>;
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
  order_by: InputMaybe<Array<Source_Order_By>>;
  where: InputMaybe<Source_Bool_Exp>;
};

export type Query_RootSource_AggregateArgs = {
  distinct_on: InputMaybe<Array<Source_Select_Column>>;
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
  order_by: InputMaybe<Array<Source_Order_By>>;
  where: InputMaybe<Source_Bool_Exp>;
};

export type Query_RootSource_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootSource_SchemaArgs = {
  source_id: Scalars['String'];
};

export type Query_RootSource_TypeArgs = {
  distinct_on: InputMaybe<Array<Source_Type_Select_Column>>;
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
  order_by: InputMaybe<Array<Source_Type_Order_By>>;
  where: InputMaybe<Source_Type_Bool_Exp>;
};

export type Query_RootSource_Type_AggregateArgs = {
  distinct_on: InputMaybe<Array<Source_Type_Select_Column>>;
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
  order_by: InputMaybe<Array<Source_Type_Order_By>>;
  where: InputMaybe<Source_Type_Bool_Exp>;
};

export type Query_RootSource_Type_By_PkArgs = {
  id: Scalars['uuid'];
};

/** columns and relationships of "source" */
export type Source = {
  __typename?: 'source';
  config: Scalars['jsonb'];
  created_at: Maybe<Scalars['timestamptz']>;
  id: Scalars['uuid'];
  name: Scalars['String'];
  schema: Maybe<Array<Table>>;
  /** An object relationship */
  source_type: Source_Type;
  source_type_id: Scalars['uuid'];
  user_id: Scalars['uuid'];
};

/** columns and relationships of "source" */
export type SourceConfigArgs = {
  path: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "source" */
export type Source_Aggregate = {
  __typename?: 'source_aggregate';
  aggregate: Maybe<Source_Aggregate_Fields>;
  nodes: Array<Source>;
};

/** aggregate fields of "source" */
export type Source_Aggregate_Fields = {
  __typename?: 'source_aggregate_fields';
  count: Scalars['Int'];
  max: Maybe<Source_Max_Fields>;
  min: Maybe<Source_Min_Fields>;
};

/** aggregate fields of "source" */
export type Source_Aggregate_FieldsCountArgs = {
  columns: InputMaybe<Array<Source_Select_Column>>;
  distinct: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Source_Append_Input = {
  config: InputMaybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "source". All fields are combined with a logical 'AND'. */
export type Source_Bool_Exp = {
  _and: InputMaybe<Array<Source_Bool_Exp>>;
  _not: InputMaybe<Source_Bool_Exp>;
  _or: InputMaybe<Array<Source_Bool_Exp>>;
  config: InputMaybe<Jsonb_Comparison_Exp>;
  created_at: InputMaybe<Timestamptz_Comparison_Exp>;
  id: InputMaybe<Uuid_Comparison_Exp>;
  name: InputMaybe<String_Comparison_Exp>;
  source_type: InputMaybe<Source_Type_Bool_Exp>;
  source_type_id: InputMaybe<Uuid_Comparison_Exp>;
  user_id: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "source" */
export enum Source_Constraint {
  /** unique or primary key constraint on columns "id" */
  SourcePkey = 'source_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Source_Delete_At_Path_Input = {
  config: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Source_Delete_Elem_Input = {
  config: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Source_Delete_Key_Input = {
  config: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "source" */
export type Source_Insert_Input = {
  config: InputMaybe<Scalars['jsonb']>;
  created_at: InputMaybe<Scalars['timestamptz']>;
  id: InputMaybe<Scalars['uuid']>;
  name: InputMaybe<Scalars['String']>;
  source_type: InputMaybe<Source_Type_Obj_Rel_Insert_Input>;
  source_type_id: InputMaybe<Scalars['uuid']>;
  user_id: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Source_Max_Fields = {
  __typename?: 'source_max_fields';
  created_at: Maybe<Scalars['timestamptz']>;
  id: Maybe<Scalars['uuid']>;
  name: Maybe<Scalars['String']>;
  source_type_id: Maybe<Scalars['uuid']>;
  user_id: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Source_Min_Fields = {
  __typename?: 'source_min_fields';
  created_at: Maybe<Scalars['timestamptz']>;
  id: Maybe<Scalars['uuid']>;
  name: Maybe<Scalars['String']>;
  source_type_id: Maybe<Scalars['uuid']>;
  user_id: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "source" */
export type Source_Mutation_Response = {
  __typename?: 'source_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Source>;
};

/** input type for inserting object relation for remote table "source" */
export type Source_Obj_Rel_Insert_Input = {
  data: Source_Insert_Input;
  /** upsert condition */
  on_conflict: InputMaybe<Source_On_Conflict>;
};

/** on_conflict condition type for table "source" */
export type Source_On_Conflict = {
  constraint: Source_Constraint;
  update_columns: Array<Source_Update_Column>;
  where: InputMaybe<Source_Bool_Exp>;
};

/** Ordering options when selecting data from "source". */
export type Source_Order_By = {
  config: InputMaybe<Order_By>;
  created_at: InputMaybe<Order_By>;
  id: InputMaybe<Order_By>;
  name: InputMaybe<Order_By>;
  source_type: InputMaybe<Source_Type_Order_By>;
  source_type_id: InputMaybe<Order_By>;
  user_id: InputMaybe<Order_By>;
};

/** primary key columns input for table: source */
export type Source_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Source_Prepend_Input = {
  config: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "source" */
export enum Source_Select_Column {
  /** column name */
  Config = 'config',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  SourceTypeId = 'source_type_id',
  /** column name */
  UserId = 'user_id',
}

/** input type for updating data in table "source" */
export type Source_Set_Input = {
  config: InputMaybe<Scalars['jsonb']>;
  created_at: InputMaybe<Scalars['timestamptz']>;
  id: InputMaybe<Scalars['uuid']>;
  name: InputMaybe<Scalars['String']>;
  source_type_id: InputMaybe<Scalars['uuid']>;
  user_id: InputMaybe<Scalars['uuid']>;
};

/** columns and relationships of "source_type" */
export type Source_Type = {
  __typename?: 'source_type';
  config_schema: Scalars['jsonb'];
  id: Scalars['uuid'];
  name: Scalars['String'];
};

/** columns and relationships of "source_type" */
export type Source_TypeConfig_SchemaArgs = {
  path: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "source_type" */
export type Source_Type_Aggregate = {
  __typename?: 'source_type_aggregate';
  aggregate: Maybe<Source_Type_Aggregate_Fields>;
  nodes: Array<Source_Type>;
};

/** aggregate fields of "source_type" */
export type Source_Type_Aggregate_Fields = {
  __typename?: 'source_type_aggregate_fields';
  count: Scalars['Int'];
  max: Maybe<Source_Type_Max_Fields>;
  min: Maybe<Source_Type_Min_Fields>;
};

/** aggregate fields of "source_type" */
export type Source_Type_Aggregate_FieldsCountArgs = {
  columns: InputMaybe<Array<Source_Type_Select_Column>>;
  distinct: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Source_Type_Append_Input = {
  config_schema: InputMaybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "source_type". All fields are combined with a logical 'AND'. */
export type Source_Type_Bool_Exp = {
  _and: InputMaybe<Array<Source_Type_Bool_Exp>>;
  _not: InputMaybe<Source_Type_Bool_Exp>;
  _or: InputMaybe<Array<Source_Type_Bool_Exp>>;
  config_schema: InputMaybe<Jsonb_Comparison_Exp>;
  id: InputMaybe<Uuid_Comparison_Exp>;
  name: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "source_type" */
export enum Source_Type_Constraint {
  /** unique or primary key constraint on columns "name" */
  SourceTypeNameKey = 'source_type_name_key',
  /** unique or primary key constraint on columns "id" */
  SourceTypePkey = 'source_type_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Source_Type_Delete_At_Path_Input = {
  config_schema: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Source_Type_Delete_Elem_Input = {
  config_schema: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Source_Type_Delete_Key_Input = {
  config_schema: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "source_type" */
export type Source_Type_Insert_Input = {
  config_schema: InputMaybe<Scalars['jsonb']>;
  id: InputMaybe<Scalars['uuid']>;
  name: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Source_Type_Max_Fields = {
  __typename?: 'source_type_max_fields';
  id: Maybe<Scalars['uuid']>;
  name: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Source_Type_Min_Fields = {
  __typename?: 'source_type_min_fields';
  id: Maybe<Scalars['uuid']>;
  name: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "source_type" */
export type Source_Type_Mutation_Response = {
  __typename?: 'source_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Source_Type>;
};

/** input type for inserting object relation for remote table "source_type" */
export type Source_Type_Obj_Rel_Insert_Input = {
  data: Source_Type_Insert_Input;
  /** upsert condition */
  on_conflict: InputMaybe<Source_Type_On_Conflict>;
};

/** on_conflict condition type for table "source_type" */
export type Source_Type_On_Conflict = {
  constraint: Source_Type_Constraint;
  update_columns: Array<Source_Type_Update_Column>;
  where: InputMaybe<Source_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "source_type". */
export type Source_Type_Order_By = {
  config_schema: InputMaybe<Order_By>;
  id: InputMaybe<Order_By>;
  name: InputMaybe<Order_By>;
};

/** primary key columns input for table: source_type */
export type Source_Type_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Source_Type_Prepend_Input = {
  config_schema: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "source_type" */
export enum Source_Type_Select_Column {
  /** column name */
  ConfigSchema = 'config_schema',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
}

/** input type for updating data in table "source_type" */
export type Source_Type_Set_Input = {
  config_schema: InputMaybe<Scalars['jsonb']>;
  id: InputMaybe<Scalars['uuid']>;
  name: InputMaybe<Scalars['String']>;
};

/** update columns of table "source_type" */
export enum Source_Type_Update_Column {
  /** column name */
  ConfigSchema = 'config_schema',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
}

/** update columns of table "source" */
export enum Source_Update_Column {
  /** column name */
  Config = 'config',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  SourceTypeId = 'source_type_id',
  /** column name */
  UserId = 'user_id',
}

export type Subscription_Root = {
  __typename?: 'subscription_root';
  /** fetch data from the table: "destination" */
  destination: Array<Destination>;
  /** fetch aggregated fields from the table: "destination" */
  destination_aggregate: Destination_Aggregate;
  /** fetch data from the table: "destination" using primary key columns */
  destination_by_pk: Maybe<Destination>;
  /** fetch data from the table: "job" */
  job: Array<Job>;
  /** fetch aggregated fields from the table: "job" */
  job_aggregate: Job_Aggregate;
  /** fetch data from the table: "job" using primary key columns */
  job_by_pk: Maybe<Job>;
  /** fetch data from the table: "model" */
  model: Array<Model>;
  /** fetch aggregated fields from the table: "model" */
  model_aggregate: Model_Aggregate;
  /** fetch data from the table: "model" using primary key columns */
  model_by_pk: Maybe<Model>;
  /** fetch data from the table: "source" */
  source: Array<Source>;
  /** fetch aggregated fields from the table: "source" */
  source_aggregate: Source_Aggregate;
  /** fetch data from the table: "source" using primary key columns */
  source_by_pk: Maybe<Source>;
  /** fetch data from the table: "source_type" */
  source_type: Array<Source_Type>;
  /** fetch aggregated fields from the table: "source_type" */
  source_type_aggregate: Source_Type_Aggregate;
  /** fetch data from the table: "source_type" using primary key columns */
  source_type_by_pk: Maybe<Source_Type>;
};

export type Subscription_RootDestinationArgs = {
  distinct_on: InputMaybe<Array<Destination_Select_Column>>;
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
  order_by: InputMaybe<Array<Destination_Order_By>>;
  where: InputMaybe<Destination_Bool_Exp>;
};

export type Subscription_RootDestination_AggregateArgs = {
  distinct_on: InputMaybe<Array<Destination_Select_Column>>;
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
  order_by: InputMaybe<Array<Destination_Order_By>>;
  where: InputMaybe<Destination_Bool_Exp>;
};

export type Subscription_RootDestination_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootJobArgs = {
  distinct_on: InputMaybe<Array<Job_Select_Column>>;
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
  order_by: InputMaybe<Array<Job_Order_By>>;
  where: InputMaybe<Job_Bool_Exp>;
};

export type Subscription_RootJob_AggregateArgs = {
  distinct_on: InputMaybe<Array<Job_Select_Column>>;
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
  order_by: InputMaybe<Array<Job_Order_By>>;
  where: InputMaybe<Job_Bool_Exp>;
};

export type Subscription_RootJob_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootModelArgs = {
  distinct_on: InputMaybe<Array<Model_Select_Column>>;
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
  order_by: InputMaybe<Array<Model_Order_By>>;
  where: InputMaybe<Model_Bool_Exp>;
};

export type Subscription_RootModel_AggregateArgs = {
  distinct_on: InputMaybe<Array<Model_Select_Column>>;
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
  order_by: InputMaybe<Array<Model_Order_By>>;
  where: InputMaybe<Model_Bool_Exp>;
};

export type Subscription_RootModel_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootSourceArgs = {
  distinct_on: InputMaybe<Array<Source_Select_Column>>;
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
  order_by: InputMaybe<Array<Source_Order_By>>;
  where: InputMaybe<Source_Bool_Exp>;
};

export type Subscription_RootSource_AggregateArgs = {
  distinct_on: InputMaybe<Array<Source_Select_Column>>;
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
  order_by: InputMaybe<Array<Source_Order_By>>;
  where: InputMaybe<Source_Bool_Exp>;
};

export type Subscription_RootSource_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootSource_TypeArgs = {
  distinct_on: InputMaybe<Array<Source_Type_Select_Column>>;
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
  order_by: InputMaybe<Array<Source_Type_Order_By>>;
  where: InputMaybe<Source_Type_Bool_Exp>;
};

export type Subscription_RootSource_Type_AggregateArgs = {
  distinct_on: InputMaybe<Array<Source_Type_Select_Column>>;
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
  order_by: InputMaybe<Array<Source_Type_Order_By>>;
  where: InputMaybe<Source_Type_Bool_Exp>;
};

export type Subscription_RootSource_Type_By_PkArgs = {
  id: Scalars['uuid'];
};

/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type Timestamptz_Comparison_Exp = {
  _eq: InputMaybe<Scalars['timestamptz']>;
  _gt: InputMaybe<Scalars['timestamptz']>;
  _gte: InputMaybe<Scalars['timestamptz']>;
  _in: InputMaybe<Array<Scalars['timestamptz']>>;
  _is_null: InputMaybe<Scalars['Boolean']>;
  _lt: InputMaybe<Scalars['timestamptz']>;
  _lte: InputMaybe<Scalars['timestamptz']>;
  _neq: InputMaybe<Scalars['timestamptz']>;
  _nin: InputMaybe<Array<Scalars['timestamptz']>>;
};

/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export type Uuid_Comparison_Exp = {
  _eq: InputMaybe<Scalars['uuid']>;
  _gt: InputMaybe<Scalars['uuid']>;
  _gte: InputMaybe<Scalars['uuid']>;
  _in: InputMaybe<Array<Scalars['uuid']>>;
  _is_null: InputMaybe<Scalars['Boolean']>;
  _lt: InputMaybe<Scalars['uuid']>;
  _lte: InputMaybe<Scalars['uuid']>;
  _neq: InputMaybe<Scalars['uuid']>;
  _nin: InputMaybe<Array<Scalars['uuid']>>;
};

export type GetSourcesQueryVariables = Types.Exact<{ [key: string]: never }>;

export type GetSourcesQuery = {
  __typename?: 'query_root';
  source: Array<{
    __typename?: 'source';
    id: any;
    name: string;
    config: any;
    created_at: any | null;
    source_type: {
      __typename?: 'source_type';
      id: any;
      name: string;
      config_schema: any;
    };
  }>;
};

export type GetSourceTypesQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type GetSourceTypesQuery = {
  __typename?: 'query_root';
  source_type: Array<{
    __typename?: 'source_type';
    id: any;
    name: string;
    config_schema: any;
  }>;
};

export type GetJobsQueryVariables = Types.Exact<{ [key: string]: never }>;

export type GetJobsQuery = {
  __typename?: 'query_root';
  job: Array<{
    __typename?: 'job';
    id: any;
    name: string;
    schedule: string;
    message_template: any;
    created_at: any | null;
    meta: any;
    active: boolean;
    destination: { __typename?: 'destination'; id: any; config: any };
    model: {
      __typename?: 'model';
      id: any;
      name: string;
      query: string;
      created_at: any | null;
      source: {
        __typename?: 'source';
        id: any;
        name: string;
        config: any;
        schema: Array<{
          __typename?: 'Table';
          name: string;
          columns: Array<{
            __typename?: 'Column';
            name: string;
            type: string;
            nullable: boolean;
            default: string | null;
          } | null>;
        }> | null;
        source_type: {
          __typename?: 'source_type';
          id: any;
          name: string;
          config_schema: any;
        };
      };
    };
  }>;
};

export type GetSourceByIdQueryVariables = Types.Exact<{
  id: Types.Scalars['uuid'];
}>;

export type GetSourceByIdQuery = {
  __typename?: 'query_root';
  source_by_pk: {
    __typename?: 'source';
    id: any;
    name: string;
    config: any;
    schema: Array<{
      __typename?: 'Table';
      name: string;
      columns: Array<{
        __typename?: 'Column';
        name: string;
        type: string;
        nullable: boolean;
        default: string | null;
      } | null>;
    }> | null;
    source_type: {
      __typename?: 'source_type';
      id: any;
      name: string;
      config_schema: any;
    };
  } | null;
};

export type GetJobByIdQueryVariables = Types.Exact<{
  id: Types.Scalars['uuid'];
}>;

export type GetJobByIdQuery = {
  __typename?: 'query_root';
  job_by_pk: {
    __typename?: 'job';
    id: any;
    name: string;
    schedule: string;
    message_template: any;
    created_at: any | null;
    meta: any;
    active: boolean;
    destination: { __typename?: 'destination'; id: any; config: any };
    model: {
      __typename?: 'model';
      id: any;
      name: string;
      query: string;
      created_at: any | null;
      source: {
        __typename?: 'source';
        id: any;
        name: string;
        config: any;
        schema: Array<{
          __typename?: 'Table';
          name: string;
          columns: Array<{
            __typename?: 'Column';
            name: string;
            type: string;
            nullable: boolean;
            default: string | null;
          } | null>;
        }> | null;
        source_type: {
          __typename?: 'source_type';
          id: any;
          name: string;
          config_schema: any;
        };
      };
    };
  } | null;
};

export type GetJobByIdWithRunsQueryVariables = Types.Exact<{
  id: Types.Scalars['uuid'];
}>;

export type GetJobByIdWithRunsQuery = {
  __typename?: 'query_root';
  job_by_pk: {
    __typename?: 'job';
    id: any;
    name: string;
    schedule: string;
    message_template: any;
    created_at: any | null;
    meta: any;
    active: boolean;
    job_runs: Array<{
      __typename?: 'JobRun';
      created_at: string | null;
      status: string | null;
      tries: number | null;
      scheduled_time: string | null;
      next_retry_at: string | null;
      id: string | null;
    }> | null;
    destination: { __typename?: 'destination'; id: any; config: any };
    model: {
      __typename?: 'model';
      id: any;
      name: string;
      query: string;
      created_at: any | null;
      source: {
        __typename?: 'source';
        id: any;
        name: string;
        config: any;
        schema: Array<{
          __typename?: 'Table';
          name: string;
          columns: Array<{
            __typename?: 'Column';
            name: string;
            type: string;
            nullable: boolean;
            default: string | null;
          } | null>;
        }> | null;
        source_type: {
          __typename?: 'source_type';
          id: any;
          name: string;
          config_schema: any;
        };
      };
    };
  } | null;
};

export type GetModelsQueryVariables = Types.Exact<{ [key: string]: never }>;

export type GetModelsQuery = {
  __typename?: 'query_root';
  model: Array<{
    __typename?: 'model';
    id: any;
    name: string;
    query: string;
    source: {
      __typename?: 'source';
      id: any;
      name: string;
      config: any;
      source_type: {
        __typename?: 'source_type';
        id: any;
        name: string;
        config_schema: any;
      };
    };
  }>;
};

export type GetDestinationsQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type GetDestinationsQuery = {
  __typename?: 'query_root';
  destination: Array<{
    __typename?: 'destination';
    id: any;
    name: string;
    config: any;
  }>;
};

export type GetDashboardStatsQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type GetDashboardStatsQuery = {
  __typename?: 'query_root';
  model_aggregate: {
    __typename?: 'model_aggregate';
    aggregate: { __typename?: 'model_aggregate_fields'; count: number } | null;
  };
  job_aggregate: {
    __typename?: 'job_aggregate';
    aggregate: { __typename?: 'job_aggregate_fields'; count: number } | null;
  };
  source_aggregate: {
    __typename?: 'source_aggregate';
    aggregate: { __typename?: 'source_aggregate_fields'; count: number } | null;
  };
  destination_aggregate: {
    __typename?: 'destination_aggregate';
    aggregate: {
      __typename?: 'destination_aggregate_fields';
      count: number;
    } | null;
  };
};

export type GetSlackChannelsQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type GetSlackChannelsQuery = {
  __typename?: 'query_root';
  slack_channels: Array<{
    __typename?: 'SlackChannel';
    id: string;
    name: string;
  }> | null;
};

export type GetSlackInstallUrlQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type GetSlackInstallUrlQuery = {
  __typename?: 'query_root';
  slack_install_url: {
    __typename?: 'SlackInstallUrlResponse';
    install_url: string;
  } | null;
};

export type GetGoogleSheetsQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type GetGoogleSheetsQuery = {
  __typename?: 'query_root';
  google_sheets: Array<{
    __typename?: 'GoogleSheet';
    id: string;
    name: string;
  }> | null;
};

export const GetSourcesDocument = gql`
  query GetSources {
    source(order_by: { created_at: desc }) {
      id
      name
      config
      created_at
      source_type {
        id
        name
        config_schema
      }
    }
  }
`;
export function useGetSourcesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetSourcesQuery,
    GetSourcesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetSourcesQuery, GetSourcesQueryVariables>(
    GetSourcesDocument,
    options
  );
}
export function useGetSourcesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSourcesQuery,
    GetSourcesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetSourcesQuery, GetSourcesQueryVariables>(
    GetSourcesDocument,
    options
  );
}
export type GetSourcesQueryHookResult = ReturnType<typeof useGetSourcesQuery>;
export type GetSourcesLazyQueryHookResult = ReturnType<
  typeof useGetSourcesLazyQuery
>;
export type GetSourcesQueryResult = Apollo.QueryResult<
  GetSourcesQuery,
  GetSourcesQueryVariables
>;
export const GetSourceTypesDocument = gql`
  query GetSourceTypes {
    source_type {
      ...SourceTypeFragment
    }
  }
  ${SourceTypeFragmentFragmentDoc}
`;
export function useGetSourceTypesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetSourceTypesQuery,
    GetSourceTypesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetSourceTypesQuery, GetSourceTypesQueryVariables>(
    GetSourceTypesDocument,
    options
  );
}
export function useGetSourceTypesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSourceTypesQuery,
    GetSourceTypesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetSourceTypesQuery, GetSourceTypesQueryVariables>(
    GetSourceTypesDocument,
    options
  );
}
export type GetSourceTypesQueryHookResult = ReturnType<
  typeof useGetSourceTypesQuery
>;
export type GetSourceTypesLazyQueryHookResult = ReturnType<
  typeof useGetSourceTypesLazyQuery
>;
export type GetSourceTypesQueryResult = Apollo.QueryResult<
  GetSourceTypesQuery,
  GetSourceTypesQueryVariables
>;
export const GetJobsDocument = gql`
  query GetJobs {
    job(order_by: { created_at: desc }) {
      ...JobFragment
    }
  }
  ${JobFragmentFragmentDoc}
`;
export function useGetJobsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetJobsQuery, GetJobsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetJobsQuery, GetJobsQueryVariables>(
    GetJobsDocument,
    options
  );
}
export function useGetJobsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetJobsQuery, GetJobsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetJobsQuery, GetJobsQueryVariables>(
    GetJobsDocument,
    options
  );
}
export type GetJobsQueryHookResult = ReturnType<typeof useGetJobsQuery>;
export type GetJobsLazyQueryHookResult = ReturnType<typeof useGetJobsLazyQuery>;
export type GetJobsQueryResult = Apollo.QueryResult<
  GetJobsQuery,
  GetJobsQueryVariables
>;
export const GetSourceByIdDocument = gql`
  query GetSourceById($id: uuid!) {
    source_by_pk(id: $id) {
      ...SourceFragment
    }
  }
  ${SourceFragmentFragmentDoc}
`;
export function useGetSourceByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetSourceByIdQuery,
    GetSourceByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetSourceByIdQuery, GetSourceByIdQueryVariables>(
    GetSourceByIdDocument,
    options
  );
}
export function useGetSourceByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSourceByIdQuery,
    GetSourceByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetSourceByIdQuery, GetSourceByIdQueryVariables>(
    GetSourceByIdDocument,
    options
  );
}
export type GetSourceByIdQueryHookResult = ReturnType<
  typeof useGetSourceByIdQuery
>;
export type GetSourceByIdLazyQueryHookResult = ReturnType<
  typeof useGetSourceByIdLazyQuery
>;
export type GetSourceByIdQueryResult = Apollo.QueryResult<
  GetSourceByIdQuery,
  GetSourceByIdQueryVariables
>;
export const GetJobByIdDocument = gql`
  query GetJobById($id: uuid!) {
    job_by_pk(id: $id) {
      ...JobFragment
    }
  }
  ${JobFragmentFragmentDoc}
`;
export function useGetJobByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetJobByIdQuery,
    GetJobByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetJobByIdQuery, GetJobByIdQueryVariables>(
    GetJobByIdDocument,
    options
  );
}
export function useGetJobByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetJobByIdQuery,
    GetJobByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetJobByIdQuery, GetJobByIdQueryVariables>(
    GetJobByIdDocument,
    options
  );
}
export type GetJobByIdQueryHookResult = ReturnType<typeof useGetJobByIdQuery>;
export type GetJobByIdLazyQueryHookResult = ReturnType<
  typeof useGetJobByIdLazyQuery
>;
export type GetJobByIdQueryResult = Apollo.QueryResult<
  GetJobByIdQuery,
  GetJobByIdQueryVariables
>;
export const GetJobByIdWithRunsDocument = gql`
  query GetJobByIdWithRuns($id: uuid!) {
    job_by_pk(id: $id) {
      ...JobFragment
      job_runs {
        created_at
        status
        tries
        scheduled_time
        next_retry_at
        id
      }
    }
  }
  ${JobFragmentFragmentDoc}
`;
export function useGetJobByIdWithRunsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetJobByIdWithRunsQuery,
    GetJobByIdWithRunsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetJobByIdWithRunsQuery,
    GetJobByIdWithRunsQueryVariables
  >(GetJobByIdWithRunsDocument, options);
}
export function useGetJobByIdWithRunsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetJobByIdWithRunsQuery,
    GetJobByIdWithRunsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetJobByIdWithRunsQuery,
    GetJobByIdWithRunsQueryVariables
  >(GetJobByIdWithRunsDocument, options);
}
export type GetJobByIdWithRunsQueryHookResult = ReturnType<
  typeof useGetJobByIdWithRunsQuery
>;
export type GetJobByIdWithRunsLazyQueryHookResult = ReturnType<
  typeof useGetJobByIdWithRunsLazyQuery
>;
export type GetJobByIdWithRunsQueryResult = Apollo.QueryResult<
  GetJobByIdWithRunsQuery,
  GetJobByIdWithRunsQueryVariables
>;
export const GetModelsDocument = gql`
  query GetModels {
    model {
      id
      name
      source {
        id
        name
        config
        source_type {
          id
          name
          config_schema
        }
      }
      query
    }
  }
`;
export function useGetModelsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetModelsQuery, GetModelsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetModelsQuery, GetModelsQueryVariables>(
    GetModelsDocument,
    options
  );
}
export function useGetModelsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetModelsQuery,
    GetModelsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetModelsQuery, GetModelsQueryVariables>(
    GetModelsDocument,
    options
  );
}
export type GetModelsQueryHookResult = ReturnType<typeof useGetModelsQuery>;
export type GetModelsLazyQueryHookResult = ReturnType<
  typeof useGetModelsLazyQuery
>;
export type GetModelsQueryResult = Apollo.QueryResult<
  GetModelsQuery,
  GetModelsQueryVariables
>;
export const GetDestinationsDocument = gql`
  query GetDestinations {
    destination {
      id
      name
      config
    }
  }
`;
export function useGetDestinationsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetDestinationsQuery,
    GetDestinationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetDestinationsQuery, GetDestinationsQueryVariables>(
    GetDestinationsDocument,
    options
  );
}
export function useGetDestinationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDestinationsQuery,
    GetDestinationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetDestinationsQuery,
    GetDestinationsQueryVariables
  >(GetDestinationsDocument, options);
}
export type GetDestinationsQueryHookResult = ReturnType<
  typeof useGetDestinationsQuery
>;
export type GetDestinationsLazyQueryHookResult = ReturnType<
  typeof useGetDestinationsLazyQuery
>;
export type GetDestinationsQueryResult = Apollo.QueryResult<
  GetDestinationsQuery,
  GetDestinationsQueryVariables
>;
export const GetDashboardStatsDocument = gql`
  query GetDashboardStats {
    model_aggregate {
      aggregate {
        count
      }
    }
    job_aggregate {
      aggregate {
        count
      }
    }
    source_aggregate {
      aggregate {
        count
      }
    }
    destination_aggregate {
      aggregate {
        count
      }
    }
  }
`;
export function useGetDashboardStatsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetDashboardStatsQuery,
    GetDashboardStatsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetDashboardStatsQuery,
    GetDashboardStatsQueryVariables
  >(GetDashboardStatsDocument, options);
}
export function useGetDashboardStatsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDashboardStatsQuery,
    GetDashboardStatsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetDashboardStatsQuery,
    GetDashboardStatsQueryVariables
  >(GetDashboardStatsDocument, options);
}
export type GetDashboardStatsQueryHookResult = ReturnType<
  typeof useGetDashboardStatsQuery
>;
export type GetDashboardStatsLazyQueryHookResult = ReturnType<
  typeof useGetDashboardStatsLazyQuery
>;
export type GetDashboardStatsQueryResult = Apollo.QueryResult<
  GetDashboardStatsQuery,
  GetDashboardStatsQueryVariables
>;
export const GetSlackChannelsDocument = gql`
  query GetSlackChannels {
    slack_channels {
      id
      name
    }
  }
`;
export function useGetSlackChannelsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetSlackChannelsQuery,
    GetSlackChannelsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetSlackChannelsQuery, GetSlackChannelsQueryVariables>(
    GetSlackChannelsDocument,
    options
  );
}
export function useGetSlackChannelsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSlackChannelsQuery,
    GetSlackChannelsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetSlackChannelsQuery,
    GetSlackChannelsQueryVariables
  >(GetSlackChannelsDocument, options);
}
export type GetSlackChannelsQueryHookResult = ReturnType<
  typeof useGetSlackChannelsQuery
>;
export type GetSlackChannelsLazyQueryHookResult = ReturnType<
  typeof useGetSlackChannelsLazyQuery
>;
export type GetSlackChannelsQueryResult = Apollo.QueryResult<
  GetSlackChannelsQuery,
  GetSlackChannelsQueryVariables
>;
export const GetSlackInstallUrlDocument = gql`
  query GetSlackInstallUrl {
    slack_install_url {
      install_url
    }
  }
`;
export function useGetSlackInstallUrlQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetSlackInstallUrlQuery,
    GetSlackInstallUrlQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetSlackInstallUrlQuery,
    GetSlackInstallUrlQueryVariables
  >(GetSlackInstallUrlDocument, options);
}
export function useGetSlackInstallUrlLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSlackInstallUrlQuery,
    GetSlackInstallUrlQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetSlackInstallUrlQuery,
    GetSlackInstallUrlQueryVariables
  >(GetSlackInstallUrlDocument, options);
}
export type GetSlackInstallUrlQueryHookResult = ReturnType<
  typeof useGetSlackInstallUrlQuery
>;
export type GetSlackInstallUrlLazyQueryHookResult = ReturnType<
  typeof useGetSlackInstallUrlLazyQuery
>;
export type GetSlackInstallUrlQueryResult = Apollo.QueryResult<
  GetSlackInstallUrlQuery,
  GetSlackInstallUrlQueryVariables
>;
export const GetGoogleSheetsDocument = gql`
  query GetGoogleSheets {
    google_sheets {
      id
      name
    }
  }
`;
export function useGetGoogleSheetsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetGoogleSheetsQuery,
    GetGoogleSheetsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetGoogleSheetsQuery, GetGoogleSheetsQueryVariables>(
    GetGoogleSheetsDocument,
    options
  );
}
export function useGetGoogleSheetsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetGoogleSheetsQuery,
    GetGoogleSheetsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetGoogleSheetsQuery,
    GetGoogleSheetsQueryVariables
  >(GetGoogleSheetsDocument, options);
}
export type GetGoogleSheetsQueryHookResult = ReturnType<
  typeof useGetGoogleSheetsQuery
>;
export type GetGoogleSheetsLazyQueryHookResult = ReturnType<
  typeof useGetGoogleSheetsLazyQuery
>;
export type GetGoogleSheetsQueryResult = Apollo.QueryResult<
  GetGoogleSheetsQuery,
  GetGoogleSheetsQueryVariables
>;
