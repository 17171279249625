import styled from 'styled-components';

export default styled.form`
  justify-content: space-around;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  grow: 1;
  width: 100%;
  max-width: 600px;
`;
