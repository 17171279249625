import {
  Button,
  IconDatabase,
  IconEdit,
  IconFastForward,
  IconInfo,
  IconPlus,
  Input,
  Listbox,
  Select,
} from '@supabase/ui';
import SecondaryHeader from '../components/SecondaryHeader';
import React, { useEffect, useState } from 'react';
import {
  GetSourcesDocument,
  useGetDestinationsQuery,
  useGetSourcesQuery,
} from '../graphql/__generated__/queries';
import {
  useActivateJobMutation,
  useCreateJobMutation,
  useCreateModelMutation,
  useCreateSampleSourceMutation,
} from '../graphql/__generated__/mutations';
import useSubmit from '../hooks/useSubmit';
import { useNavigate } from 'react-router-dom';
import SQLEditor from '../components/SQLEditor';
import SlackDestination from '../components/SlackDestination';
import { mapToData } from '../lib/utils';
import { QueryResult } from '../types.generated';
import GoogleSheetsDestination from "../components/GoogleSheetsDestination";

const SAMPLE_QUERY = `--- revenue by customer name
SELECT 
  c.first_name || ' ' || c.last_name as customer_name, 
  SUM(total) as total FROM customer c 
LEFT JOIN invoice i ON i.customer_id = c.customer_id 
GROUP BY 1 ORDER BY 2 DESC;
`;

const SAMPLE_SLACK_MESSAGE = `Revenue by customer:
{% for row in data %}
*{{ row.customer_name }}*: \`\${{ row.total }}\`
{% endfor %}
`;

export default function CreatePush() {
  const { data } = useGetSourcesQuery();
  const [source, setSource] = useState<any>();
  const [query, setQuery] = useState<string>('');
  const [queryResult, setQueryResult] = useState<QueryResult | undefined>();
  const { data: destination } = useGetDestinationsQuery();
  const [modelMutation, { loading: modelLoading }] = useCreateModelMutation();
  const [jobMutation, { loading: jobLoading }] = useCreateJobMutation();
  const [channel, setChannel] = useState('');
  const [slackMessage, setSlackMessage] = useState('');
  const [sampleMutation, { loading: mutationLoading }] =
    useCreateSampleSourceMutation();
  const [activateMutation] = useActivateJobMutation();

  const navigate = useNavigate();

  useEffect(() => {
    if (!source) {
      setSource(data?.source[0]?.id ?? '');
    }
  }, [source, data]);

  const handleSubmit = useSubmit(
    async ({ name, active, message_template, schedule }) => {
      const { data } = await modelMutation({
        variables: {
          input: {
            name: 'Query',
            source_id: source,
            query,
          },
        },
      });

      const { data: jobData } = await jobMutation({
        variables: {
          input: {
            destination_id: destination?.destination[0]?.id ?? '',
            name,
            model_id: data?.insert_model_one?.id,
            message_template,
            schedule,
            meta: {
              slack_channel: channel,
            },
            active: !!parseInt(active),
          },
        },
      });

      if (active) {
        await activateMutation({
          variables: {
            id: jobData?.insert_job_one?.id,
          },
        });
      }

      navigate('/');
    }
  );

  async function handleCreateSample(e: any) {
    e.preventDefault();
    console.log('here');
    setQuery(SAMPLE_QUERY);
    setSlackMessage(SAMPLE_SLACK_MESSAGE);
    console.log('not here');
    await sampleMutation({
      refetchQueries: [GetSourcesDocument],
    });
  }

  return (
    <form onSubmit={handleSubmit} className={'h-screen flex flex-col'}>
      <SecondaryHeader
        title={'Create Push'}
        showButton={false}
        icon={<IconEdit/>}
      >
        <div className={'flex justify-end flex-auto'}>
          <div>
            <Button
              onClick={(e) => {
                if (e.currentTarget.form) {
                  e.currentTarget.form['active'].value = 0;
                }
              }}
              loading={modelLoading || jobLoading}
              type={'outline'}
              size={'small'}
              className={'mr-2'}
            >
              Create
            </Button>
            <Button
              onClick={(e) => {
                if (e.currentTarget.form) {
                  e.currentTarget.form['active'].value = 1;
                }
              }}
              loading={modelLoading || jobLoading}
              size={'small'}
            >
              Create and run
            </Button>
          </div>
        </div>
      </SecondaryHeader>
      <div className={'flex w-full bg-neutral-50 justify-center'}>
        <div className={'flex-auto max-w-3xl'}>
          <div className={'py-2 flex'}>
            <div className={'pt-1.5 font-medium'}>Name your push</div>
          </div>
          <Input required name={'name'} defaultValue={'Untitled Push'}/>
          <div className={'py-2 flex'}>
            <div className={'pt-1.5 font-medium'}>Select data source</div>
          </div>
          <div className={'flex'}>
            <input type={'hidden'} value={source} name={'source_id'}/>
            {!!data?.source.length && (
              <Listbox
                required
                value={source}
                icon={<IconDatabase/>}
                className={'flex-auto pr-2'}
                onChange={(e) => setSource(e)}
                name={'source_id'}
              >
                {data?.source?.map((model) => (
                  <Listbox.Option
                    key={model.id}
                    label={model.name}
                    value={model.id}
                  >
                    {model.name}
                  </Listbox.Option>
                ))}
              </Listbox>
            )}
            <Button
              size={'small'}
              type={'outline'}
              icon={<IconPlus/>}
              className={'mr-0.5'}
              block={!data?.source.length}
              onClick={() => navigate('/sources/create')}
            >
              {!data?.source.length && 'Connect new source'}
            </Button>
            {!data?.source.length && (
              <Button
                block
                loading={mutationLoading}
                size={'small'}
                type={'outline'}
                className={'ml-0.5'}
                onClick={handleCreateSample}
                icon={<IconFastForward/>}
              >
                Use sample database
              </Button>
            )}
          </div>
          <SQLEditor
            source_id={source}
            value={query}
            onChange={(value) => setQuery(value ?? '')}
            onQueryResult={(value) => setQueryResult(value)}
          />
          <div>
            <div className={'py-2 flex'}>
              <div className={'pt-1.5 font-medium'}>Send data to Google Sheets</div>
            </div>
            <GoogleSheetsDestination  />
            <div className={'py-2 flex items-end'}>
              <div className={'pt-1.5 font-medium'}>Send message to Slack</div>
              <div className={'text-left pb-0.5 pl-2 text-neutral-700 text-xs'}>
                <IconInfo className={'w-4 h-4 pb-0.5 inline-flex'}/> Use
                Slack's{' '}
                <a
                  href={
                    'https://api.slack.com/reference/surfaces/formatting#basics'
                  }
                  target={'_blank'}
                  className={'underline'}
                >
                  mrkdwn
                </a>{' '}
                syntax for formatting.
              </div>
            </div>
            <SlackDestination
              slackChannelId={channel}
              slackMessage={slackMessage}
              onChange={(channel_id, message) => {
                setChannel(channel_id ?? '');
                setSlackMessage(message ?? '');
              }}
              params={{
                data: queryResult && mapToData(queryResult),
              }}
            />
            <div className={'py-2 flex'}>
              <div className={'pt-1.5 font-medium'}>
                Configure your schedule
              </div>
            </div>
            <Select required name={'schedule'}>
              <Select.Option value={'* * * * *'}>Every minute</Select.Option>
              <Select.Option value={'0 * * * *'}>Every hour</Select.Option>
              <Select.Option value={'0 0 * * *'}>Every day</Select.Option>
              <Select.Option value={'0 0 0 * *'}>Every week</Select.Option>
              <Select.Option value={'0 0 0 0 *'}>Every month</Select.Option>
            </Select>
          </div>
          <input type={'hidden'} name={'active'}/>
        </div>
      </div>
    </form>
  );
}
