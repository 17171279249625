import { IconDatabase, Input, Select } from '@supabase/ui';
import { Loading } from '../components/Loading';
import SecondaryHeader from '../components/SecondaryHeader';
import React, { useEffect, useState } from 'react';
import {
  useGetDestinationsQuery,
  useGetJobByIdQuery,
  useGetSourcesQuery,
} from '../graphql/__generated__/queries';
import {
  useUpdateJobMutation,
  useUpdateModelMutation,
} from '../graphql/__generated__/mutations';
import { QueryResult } from '../types.generated';
import useSubmit from '../hooks/useSubmit';
import { useNavigate, useParams } from 'react-router-dom';
import SQLEditor from '../components/SQLEditor';
import SlackDestination from '../components/SlackDestination';
import { mapToData } from '../lib/utils';

export default function EditPush() {
  const { id } = useParams();
  const { data: job, loading } = useGetJobByIdQuery({
    variables: {
      id,
    },
  });
  const { data } = useGetSourcesQuery();
  const [query, setQuery] = useState<string>();
  const [queryResult, setQueryResult] = useState<QueryResult | undefined>();
  const { data: destination } = useGetDestinationsQuery();
  const [modelMutation] = useUpdateModelMutation();
  const [jobMutation] = useUpdateJobMutation();
  const [channel, setChannel] = useState();
  const [message, setMessage] = useState();

  useEffect(() => {
    if (job?.job_by_pk) {
      setChannel(job?.job_by_pk?.meta?.slack_channel);
      setMessage(job?.job_by_pk?.message_template);
    }
  }, [data]);

  const navigate = useNavigate();

  const handleSubmit = useSubmit(async ({ name, source_id, schedule }) => {
    await modelMutation({
      variables: {
        id: job?.job_by_pk?.model?.id,
        input: {
          name: 'Query',
          source_id: source_id,
          query,
        },
      },
    });

    await jobMutation({
      variables: {
        id,
        input: {
          destination_id: destination?.destination[0]?.id ?? '',
          name,
          model_id: job?.job_by_pk?.model?.id,
          message_template: message,
          schedule,
          meta: {
            slack_channel: channel,
          },
        },
      },
    });

    navigate('/');
  });

  if (loading) {
    return <Loading active={loading}>Loading...</Loading>;
  }

  return (
    <form onSubmit={handleSubmit} className={'h-screen flex flex-col'}>
      <SecondaryHeader
        title={'Edit Push'}
        showButton={true}
        buttonText={'Save'}
      />
      <div className={'flex w-full justify-center'}>
        <div className={'flex-auto max-w-3xl'}>
          <div className={'py-2 flex'}>
            <div className={'pt-1.5 font-medium'}>Push name</div>
          </div>
          <Input required name={'name'} defaultValue={job?.job_by_pk?.name} />
          <div className={'py-2 flex'}>
            <div className={'pt-1.5 font-medium'}>Data source</div>
          </div>
          <Select
            disabled
            readOnly
            defaultValue={job?.job_by_pk?.model?.source?.id}
            icon={<IconDatabase />}
            size={'medium'}
            name={'source_id'}
          >
            {data?.source?.map((model) => (
              <Select.Option key={model.id} value={model.id}>
                {model.name}
              </Select.Option>
            ))}
          </Select>
          <SQLEditor
            source_id={job?.job_by_pk?.model?.source?.id}
            defaultValue={job?.job_by_pk?.model?.query}
            onChange={(value) => setQuery(value ?? '')}
            onQueryResult={(value) => setQueryResult(value)}
          />
          <div>
            <div className={'py-2 flex'}>
              <div className={'pt-1.5 font-medium'}>Add Slack message</div>
            </div>
            <SlackDestination
              slackChannelId={job?.job_by_pk?.meta.slack_channel}
              slackMessage={job?.job_by_pk?.message_template}
              params={{
                data: queryResult && mapToData(queryResult),
              }}
            />
            <div className={'py-2 flex'}>
              <div className={'pt-1.5 font-medium'}>
                Configure your schedule
              </div>
            </div>
            <Select
              required
              defaultValue={job?.job_by_pk?.schedule}
              name={'schedule'}
            >
              <Select.Option value={'* * * * *'}>Every minute</Select.Option>
              <Select.Option value={'0 * * * *'}>Every hour</Select.Option>
              <Select.Option value={'0 0 * * *'}>Every day</Select.Option>
              <Select.Option value={'0 0 0 * *'}>Every week</Select.Option>
              <Select.Option value={'0 0 0 0 *'}>Every month</Select.Option>
            </Select>
          </div>
          <input type={'hidden'} name={'active'} />
        </div>
      </div>
    </form>
  );
}
