import { PropsWithChildren } from 'react';

type Props = {
  active: boolean;
};

const Logo = ({ ...props }) => (
  <svg
    width="24"
    height="22"
    viewBox="0 0 24 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.875 10.875L12.2892 12.2892L13.7034 10.875L12.2892 9.46079L10.875 10.875ZM3.41421 21.1642L12.2892 12.2892L9.46079 9.46079L0.585787 18.3358L3.41421 21.1642ZM12.2892 9.46079L3.41421 0.585786L0.585786 3.41421L9.46079 12.2892L12.2892 9.46079Z"
      fill="#151515"
    />
    <path
      d="M20.875 10.875L22.2892 12.2892L23.7034 10.875L22.2892 9.46079L20.875 10.875ZM13.4142 21.1642L22.2892 12.2892L19.4608 9.46079L10.5858 18.3358L13.4142 21.1642ZM22.2892 9.46079L13.4142 0.585786L10.5858 3.41421L19.4608 12.2892L22.2892 9.46079Z"
      fill="#151515"
    />
  </svg>
);

export function Loading({ active, children }: PropsWithChildren<Props>) {
  return (
    <>
      {active && (
        <Logo
          className={
            'animate-ping absolute top-0 bottom-0 left-0 right-0 m-auto'
          }
        />
      )}
      <div
        className={
          'transition-all flex-auto transition-opacity duration-600 ' +
          (active ? 'opacity-25' : '')
        }
      >
        {children}
      </div>
    </>
  );
}
