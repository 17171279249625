import { QueryResult } from '../types.generated';
import { Postgresql, Salesforce, Snowflake } from "@icons-pack/react-simple-icons";
import { IconDatabase } from "@supabase/ui";

declare global {
  namespace google {
    namespace script {
      const run: any;
    }
  }
}

export function isAddon(): boolean {
  return typeof google !== 'undefined'
}

export function addonPath(): string {
  return isAddon() ? '/' : '/addon';
}

export function getSourceIcon(source: any) {
    const sourceType = source?.source_type?.name?.toLowerCase()
    if (sourceType === 'postgresql') {
      return Postgresql
    }
    if (sourceType === 'salesforce') {
      return Salesforce
    }
    if (sourceType === 'snowflake') {
      return Snowflake
    }
    return IconDatabase
  }

export function mapToData(queryResult: QueryResult) {
  const { columns, rows } = queryResult;
  if (!columns || !rows) {
    return {};
  }
  return rows.map((row) => {
    const result: { [key: string]: any } = {};
    return row?.reduce((prev, curr, index) => {
      const column = columns[index];
      prev[column] = curr;
      return prev;
    }, result);
  });
}

export function humanizeCron(cron: string): string {
  const cronToHuman: { [key: string]: string } = {
    '0 0 0 * *': 'monthly',
    '0 0 * * *': 'daily',
    '0 * * * *': 'hourly',
    '* * * * *': 'every min.',
  };
  return cronToHuman[cron] ?? cron;
}

export function timeAgo(date: Date | string): string {
  date = new Date(date);

  const formatter = new Intl.RelativeTimeFormat(undefined, {
    numeric: 'auto',
    style: 'short',
  });

  const DIVISIONS: { amount: number; name: Intl.RelativeTimeFormatUnit }[] = [
    { amount: 60, name: 'seconds' },
    { amount: 60, name: 'minutes' },
    { amount: 24, name: 'hours' },
    { amount: 7, name: 'days' },
    { amount: 4.34524, name: 'weeks' },
    { amount: 12, name: 'months' },
    { amount: Number.POSITIVE_INFINITY, name: 'years' },
  ];

  let duration = (date.getTime() - new Date().getTime()) / 1000;

  for (let i = 0; i < DIVISIONS.length; i++) {
    const division = DIVISIONS[i];
    if (Math.abs(duration) < division.amount) {
      return formatter.format(Math.round(duration), division.name);
    }
    duration /= division.amount;
  }

  return '';
}

export function greeting() {
  return [
    'What are you doing that early?',
    'Good morning, human!',
    'Good afternoon, human!',
    'Good evening, human!',
  ][Math.trunc((new Date().getHours() / 24) * 4)];
}

export const capitalize = (s: string) => s.charAt(0).toUpperCase() + s.slice(1);
