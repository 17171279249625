import {
  useGetGoogleSheetsQuery,
} from '../../graphql/__generated__/queries';
import React, { useEffect, useState } from 'react';
import { useGoogleSheetsWriteMutation } from '../../graphql/__generated__/mutations';
import {
  Button,
  IconHash,
  IconSend,
  Listbox,
  IconSlack, IconLink,
} from '@supabase/ui';
import { toast } from 'react-toastify';
import { Loading } from '../Loading';
import { apiClient } from "../../lib/client";

export default function GoogleSheetsDestination({
                                                  spreadsheetId,
                                                  spreadsheetData,
                                                  onChange,
                                                }: {
  spreadsheetId?: string;
  spreadsheetData?: string[][];
  onChange?: (spreadsheetId?: string, spreadsheetData?: string[][]) => void;
}) {
  const token = apiClient.auth.session()?.access_token
  const { data, loading, refetch } = useGetGoogleSheetsQuery();
  const [mutation, { loading: writeLoading }] = useGoogleSheetsWriteMutation();
  const [connecting, setConnecting] = useState(false);

  async function refetchOnFocus() {
    if (document.visibilityState === 'visible') {
      await refetch();
      setConnecting(false)
    }
  }

  useEffect(() => {
    if (!spreadsheetId && data?.google_sheets?.length) {
      onChange?.(data.google_sheets[0].id, spreadsheetData);
    }

    window.document.addEventListener('visibilitychange', refetchOnFocus)

    return () => window.document.addEventListener('visibilitychange', refetchOnFocus)

  }, [data, spreadsheetId]);

  function onConnectClick() {
    setConnecting(true);
    window.open((process.env.REACT_APP_BACKEND_URL ?? 'http://localhost:4000') + '/auth/google?access_token=' + token ?? '_blank', 'toolbar=no,status=no,menubar=no,location=center,scrollbars=no,resizable=no,height=500,width=657');
  }

  async function handleSendTestMessage() {
    await mutation({
      variables: {
        data: spreadsheetData ?? [],
        id: spreadsheetId ?? ''
      },
    });
    toast.success(`Message sent successfully.`);
  }

  return (
    <Loading active={loading}>
      <div className={'flex justify-between pt-3'}>
        {!data?.google_sheets?.length && (
          <Button
            loading={connecting}
            onClick={onConnectClick}
            icon={<IconLink />}
            size={'medium'}
            block
            type={'outline'}
          >
            Connect Google Sheets
          </Button>
        )}
        {!!data?.google_sheets?.length && (
          <>
            <Listbox
              name={'slack_channel'}
              required
              value={spreadsheetId ?? undefined}
              placeholder={'Select channel'}
              icon={<IconHash/>}
              onChange={(e: any) => onChange?.(e, spreadsheetData)}
              className={'flex-auto pr-2'}
            >
              {data?.google_sheets?.map(({ name, id }, index) => (
                <Listbox.Option label={name} key={id} value={id}>
                  {name}
                </Listbox.Option>
              ))}
            </Listbox>
            <input
              type={'hidden'}
              name={'slack_channel'}
              value={spreadsheetId}
            />
            <div>
              <Button
                disabled={!spreadsheetId}
                icon={<IconSend/>}
                type={'outline'}
                size={'medium'}
                loading={writeLoading}
                onClick={handleSendTestMessage}
              >
                Send test message
              </Button>
              <Button
                className={'ml-1'}
                loading={connecting}
                onClick={onConnectClick}
                icon={<IconSlack/>}
                size={'small'}
                type={'outline'}
              ></Button>
            </div>
          </>
        )}
      </div>
    </Loading>
  );
}
