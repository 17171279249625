import {
  IconDatabase,
  IconArrowRight,
  IconSlack,
  Badge,
  IconPlay,
  IconSettings,
  Button,
  IconCalendar,
  IconClock,
} from '@supabase/ui';
import SecondaryHeader from '../components/SecondaryHeader';
import React from 'react';
import {
  GetJobByIdDocument,
  GetJobsDocument,
  useGetJobByIdQuery, useGetJobByIdWithRunsQuery,
} from '../graphql/__generated__/queries';
import {
  NavLink,
  Route,
  Routes,
  useNavigate,
  useParams,
} from 'react-router-dom';
import {
  useActivateJobMutation,
  useDeactivateJobMutation,
  useDeleteJobMutation,
  useUpdateJobMutation,
} from '../graphql/__generated__/mutations';
import { capitalize, humanizeCron, timeAgo } from '../lib/utils';
import DataGrid, { FormatterProps } from 'react-data-grid';
import { Loading } from '../components/Loading';

function JobProperties({ job }: { job?: any }) {
  return (
    <div className={'grid px-5 pb-10 gap-2 grid-cols-3 text-left text-sm'}>
      <div className={'text-lg col-span-3 text-neutral-500 pt-4'}>General</div>
      <div>
        <div className={'font-semibold text-neutral-500'}>Name</div>
        {job?.name}
      </div>
      <div>
        <div className={'font-semibold text-neutral-500'}>Schedule</div>
        {humanizeCron(job?.schedule ?? '')}
      </div>
      <div>
        <div className={'font-semibold text-neutral-500'}>Created At</div>
        {new Date(job?.created_at).toLocaleString()}
      </div>
      <div className={'text-lg col-span-3 text-neutral-500 pt-4'}>Source</div>
      <div>
        <div className={'font-semibold text-neutral-500'}>Source name</div>
        {job?.model.source.name}
      </div>
      <div>
        <div className={'font-semibold text-neutral-500'}>Query</div>
        {job?.model.query}
      </div>
      <div className={'text-lg col-span-3 text-neutral-500 pt-4'}>
        Destination
      </div>
      <div>
        <div className={'font-semibold text-neutral-500'}>Slack Channel</div>
        {job?.meta?.slack_channel}
      </div>
      <div>
        <div className={'font-semibold text-neutral-500'}>Slack Message</div>
        {job?.message_template}
      </div>
    </div>
  );
}

export default function JobDetails() {
  const { id } = useParams();

  const { data, loading } = useGetJobByIdWithRunsQuery({
    variables: {
      id,
    },
  });

  const [mutation] = useUpdateJobMutation();
  const [activateMutation] = useActivateJobMutation();
  const [deactivateMutation] = useDeactivateJobMutation();
  const [deleteMutation] = useDeleteJobMutation();

  async function toggleJob(e: any, job: any) {
    e.stopPropagation();
    e.preventDefault();
    if (!job.active) {
      await activateMutation({
        variables: {
          id: job.id,
        },
      });
    } else {
      await deactivateMutation({
        variables: {
          id: job.id,
        },
      });
    }
    await mutation({
      variables: {
        id: job.id,
        input: {
          active: !job.active,
        },
      },
      refetchQueries: [GetJobByIdDocument],
    });
  }

  function statusFormatter({ row }: FormatterProps<any>) {
    const status = row.status;
    if (status === 'pending') {
      return (
        <Badge dot={true} color="blue">
          {capitalize(status)}
        </Badge>
      );
    }

    if (status === 'error') {
      return (
        <Badge dot={true} color="red">
          {capitalize(status)}
        </Badge>
      );
    }

    if (status === 'delivered') {
      return (
        <Badge dot={true} color="green">
          Success
        </Badge>
      );
    }

    return (
      <Badge dot={true} color="gray">
        {capitalize(status)}
      </Badge>
    );
  }

  const columns = [
    {
      key: 'execution',
      name: 'ID',
      formatter: ({ row }: FormatterProps<any>) => row.id,
    },
    { key: 'Status', name: 'Status', formatter: statusFormatter },
    {
      key: 'Started',
      name: 'Started',
      formatter: ({ row }: FormatterProps<any>) =>
        new Date(row.scheduled_time).toLocaleString(),
    },
  ];

  const navigate = useNavigate();

  return (
    <Loading active={loading}>
      <SecondaryHeader
        title={'Pushes / ' + data?.job_by_pk?.name ?? ''}
        showButton={false}
      >
        <div className={'flex justify-end flex-auto'}>
          <Button
            onClick={async () => {
              await deleteMutation({
                variables: {
                  id,
                },
                refetchQueries: [GetJobsDocument],
              });
              navigate(-1);
            }}
            type={'outline'}
            size={'small'}
            className={'mr-2'}
          >
            Delete
          </Button>
          <Button
            onClick={(e) => {
              navigate('/pushes/' + data?.job_by_pk?.id + '/edit');
            }}
            size={'small'}
          >
            Edit
          </Button>
        </div>
      </SecondaryHeader>
      <div className={'w-full flex justify-center'}>
        <div
          key={data?.job_by_pk?.id}
          className="relative flex flex-col w-2/3 mt-4 border border-neutral-100 shadow-sm rounded-lg bg-white py-4"
        >
          <div className={'flex h-28 flex-col px-4 pb-4'}>
            <div className={'flex h-10 w-16 justify-center items-center'}>
              <IconDatabase className="h-5 w-5" aria-hidden="true" />
              <IconArrowRight className="h-5 w-3 mx-1" aria-hidden="true" />
              <IconSlack className="h-5 w-5" aria-hidden="true" />
            </div>
            <div className={'text-left text-2xl'}>
              <div>{data?.job_by_pk?.name}</div>
              <div className={'pt-1 text-xs'}>
                <IconCalendar className={'w-4 inline-flex mr-1'} />
                {'runs ' + humanizeCron(data?.job_by_pk?.schedule ?? '')}
                <IconClock className={'w-4 inline-flex ml-2 mr-1'} />
                {'created ' +
                  timeAgo(new Date(data?.job_by_pk?.created_at ?? ''))}
              </div>
              <div
                onClick={(e) => toggleJob(e, data?.job_by_pk)}
                className={'absolute top-6 right-2 flex flex-row'}
              >
                <label
                  htmlFor="default-toggle"
                  className="inline-flex relative items-center cursor-pointer"
                >
                  <input
                    readOnly
                    type="checkbox"
                    value=""
                    id="default-toggle"
                    className="sr-only peer"
                    checked={data?.job_by_pk?.active}
                  />
                  <div className="w-11 h-6 bg-neutral-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-brand-300 dark:peer-focus:ring-brand-800 rounded-full peer dark:bg-neutral-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-neutral-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-neutral-600 peer-checked:bg-brand-600"></div>
                  <span className="ml-3 text-sm font-medium text-neutral-900 dark:text-neutral-300" />
                </label>
              </div>
            </div>
          </div>
          <div className="border-b border-neutral-200 dark:border-neutral-700">
            <ul className="flex flex-wrap -mb-px text-sm font-medium text-center text-neutral-500 dark:text-neutral-400">
              <li className="mr-2">
                <NavLink
                  end
                  to={''}
                  className={({ isActive }) =>
                    `inline-flex p-4 ${
                      isActive
                        ? 'text-brand-600 border-b-2 border-brand-600 active'
                        : 'text-neutral-500'
                    } rounded-t-lg group`
                  }
                  aria-current="page"
                >
                  <IconPlay className={'w-5 h-5 mr-2'} />
                  Runs
                </NavLink>
              </li>
              <li className="mr-2">
                <NavLink
                  end
                  to={'./details'}
                  className={({ isActive }) =>
                    `inline-flex p-4 ${
                      isActive
                        ? 'text-brand-600 border-b-2 border-brand-600 active'
                        : 'text-neutral-500'
                    } rounded-t-lg group`
                  }
                  aria-current="page"
                >
                  <IconSettings className={'w-5 h-5 mr-2'} />
                  Properties
                </NavLink>
              </li>
            </ul>
          </div>
          <Routes>
            <Route
              path={''}
              element={
                <DataGrid
                  components={{
                    noRowsFallback: (
                      <div className={'col-span-full p-2 text-center'}>
                        No job runs
                      </div>
                    ),
                  }}
                  columns={columns}
                  rows={data?.job_by_pk?.job_runs ?? []}
                />
              }
            />
            <Route
              path={'/details'}
              element={<JobProperties job={data?.job_by_pk} />}
            />
          </Routes>
        </div>
      </div>
    </Loading>
  );
}
