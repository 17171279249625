import { Auth } from '@supabase/ui';
import { apiClient } from '../../lib/client';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
`;

const StyledAuth = styled(Auth)`
  max-width: 400px;
`;

function maybeVerifyOrRecover() {
  const hash = window.location.hash.substr(1);

  if (hash.startsWith('confirmation_token=')) {
    apiClient.auth.verifyOTP({
      email: '',
      type: 'signup',
      token: decodeURIComponent(hash.substr('confirmation_token='.length)),
    });
  }

  if (hash.startsWith('recovery_token=')) {
    apiClient.auth.verifyOTP({
      email: '',
      type: 'recovery',
      token: decodeURIComponent(hash.substr('recovery_token='.length)),
    });
  }
}

export default function AuthBasic() {
  maybeVerifyOrRecover();

  return (
    <Container>
      <StyledAuth
        providers={['google']}
        redirectTo={window.location.href}
        supabaseClient={apiClient}
      />
    </Container>
  );
}
