import React, { PropsWithChildren, useEffect, useState } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import { Button, IconArrowLeft, IconSkipBack } from "@supabase/ui";
import { isAddon } from "../lib/utils";


const Addon = ({ children }: PropsWithChildren<{}>) => {

  const [loading, setLoading] = useState(true)

  const navigate = useNavigate()

  const location = useLocation();

  const addonPath = isAddon() ? '/' : '/addon';

  useEffect(() => {
    setTimeout(() => setLoading(false), 2000)
  }, [])


  return <div className={"bg-white flex h-screen"}>
    <div className={"flex-auto h-full"} style={{
      backgroundImage: 'url(' + process.env.PUBLIC_URL + '/images/sheet.png)',
      backgroundSize: '100%'
    }}></div>
    <div className={"w-[300px] h-full border-l"}>
      <div
        className={
          'flex flex-col bg-white w-full h-full relative'
        }
      >
        <div className={"flex border-b px-2 py-1 sticky top-0 bg-white z-50"}>
          {location.pathname !== addonPath && <IconArrowLeft onClick={() => navigate(-1)} className={"w-8 h-8 m-1 font-medium hover:cursor-pointer"} />}
          <img alt={'PushSQL'} className={'w-28 ml-2'} src={process.env.PUBLIC_URL + '/logo.png'}></img>
        </div>
        {/*<Loading active={loading}>*/}
          {children}
        {/*</Loading>*/}
      </div>
    </div>
  </div>
};

export default Addon;
