import { Button, IconLogOut, IconSettings } from '@supabase/ui';
import styled from 'styled-components';
import { ButtonProps } from '@supabase/ui/dist/cjs/components/Button/Button';
import React, { PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';
import { apiClient } from '../../lib/client';

const Breadcrumbs = styled.div`
  display: flex;
  justify-content: space-between;
  height: 50px;
`;

interface SecondaryHeaderProps {
  title: string;
  icon?: JSX.Element;
  buttonProps?: ButtonProps;
  buttonText?: string;
  showButton?: boolean;
}

export default function SecondaryHeader({
  buttonProps = {},
  buttonText = 'Create',
  showButton = true,
  children,
}: PropsWithChildren<SecondaryHeaderProps>) {
  return (
    <Breadcrumbs
      className={
        'flex sticky top-0 w-full bg-white border-b border-b-neutral-200 items-center justify-between px-4 z-50'
      }
    >
      <Link to={'/'}>
        <img alt={'PushSQL'} className={'w-28 pl-2'} src={'/logo.png'}></img>
      </Link>
      {children}
      <div className={'flex items-center justify-between'}>
        {showButton && (
          <Button size={'small'} {...buttonProps}>
            {buttonText}
          </Button>
        )}
        <div className={'group relative'}>
          <div className={'ml-4 relative p-2 z-50'}>
            <IconSettings
              className={'w-5 hover:text-neutral-300 hover:cursor-pointer h-5'}
            />
          </div>
          <div
            className={
              'absolute hidden group-hover:flex flex z-20 text-sm top-8 shadow-sm right-0 bg-white border rounded-md px-4 py-2'
            }
          >
            <a
              href={'/#'}
              className={'hover:text-neutral-300 hover:cursor-pointer flex'}
              onClick={() => apiClient.auth.signOut()}
            >
              <IconLogOut className={'w-5 h-5 mr-2'} />
              Logout
            </a>
          </div>
        </div>
      </div>
    </Breadcrumbs>
  );
}
