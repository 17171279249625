import { Button, IconLayers, Input } from '@supabase/ui';
import SecondaryHeader from '../components/SecondaryHeader';
import Form from '../components/Form';
import React from 'react';
import { GetDestinationsDocument } from '../graphql/__generated__/queries';
import { useNavigate } from 'react-router-dom';
import useSubmit from '../hooks/useSubmit';
import { useCreateDestinationMutation } from '../graphql/__generated__/mutations';
import PaddedContainer from '../components/PaddedContainer';

export default function CreateDestination() {
  const [mutation] = useCreateDestinationMutation();

  const navigate = useNavigate();

  const handleSubmit = useSubmit(async (formData: any) => {
    await mutation({
      variables: {
        input: {
          name: formData.name,
          config: {
            webhook: formData.webhook?.trim(),
          },
        },
      },
      refetchQueries: [GetDestinationsDocument],
    });

    navigate('/destinations');
  });

  return (
    <>
      <SecondaryHeader
        title={'Destinations / Create Destination'}
        showButton={false}
        icon={<IconLayers />}
      />
      <PaddedContainer>
        <Form onSubmit={handleSubmit}>
          <Input
            label={'Destination name'}
            name={'name'}
            placeholder={'My slack channel'}
          />
          <Input
            label={'Webhook URL'}
            name={'webhook'}
            placeholder={
              'https://hooks.slack.com/services/T00000000/B00000000/XXXXXXXXXXXXXXXXXXXXXXXX\n'
            }
          />
          <Button size={'medium'}>Create</Button>
        </Form>
      </PaddedContainer>
    </>
  );
}
