import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { apiClient } from './lib/client';
import { Auth } from '@supabase/ui';
import { BrowserRouter, HashRouter } from 'react-router-dom';
import AuthorizedApolloProvider from './components/Apollo/AuthorizedApolloProvider';
import { IntercomProvider } from 'react-use-intercom';

// @ts-ignore
const Router = typeof google === 'undefined' ? BrowserRouter : HashRouter;

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <AuthorizedApolloProvider>
        <Auth.UserContextProvider supabaseClient={apiClient}>
          {/*<IntercomProvider appId={'k7nh3y39'}>*/}
            <App />
          {/*</IntercomProvider>*/}
        </Auth.UserContextProvider>
      </AuthorizedApolloProvider>
    </Router>
  </React.StrictMode>,
  document.getElementById('root') as HTMLElement
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
