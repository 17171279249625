import { GoTrueClient } from '@supabase/gotrue-js';
import SupabaseClient from '@supabase/supabase-js/dist/module/SupabaseClient';

export class ApiClient extends SupabaseClient {}

export const apiClient = {
  auth: new GoTrueClient({
    url: process.env.REACT_APP_GOTRUE_URL ?? 'http://localhost:8081',
    headers: {},
  }),
} as ApiClient;
