import React from 'react';
import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  HttpLink,
  InMemoryCache,
  NormalizedCacheObject,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

import { onError } from '@apollo/client/link/error';

import { useNavigate } from 'react-router';

import { apiClient } from '../../lib/client';
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const AuthorizedApolloProvider = ({
                                    children,
                                  }: {
  children: JSX.Element | JSX.Element[];
}): JSX.Element => {
  const navigate = useNavigate();

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore

    if (graphQLErrors) {
      toast.error(graphQLErrors[0].message);
    }

    const errorWithCode = networkError as { statusCode: number };

    if (errorWithCode?.statusCode === 403) {
      navigate('/');
      return;
    }
  });

  const authLink = setContext(async (_, { headers, ...context }) => {
    const token = apiClient.auth.session()?.access_token;

    return {
      headers: {
        ...headers,
        ...(token ? { Authorization: `Bearer ${token}` } : {}),
      },
      ...context,
    };
  });

  const graphqlUrl = (process.env.REACT_APP_GRAPHQL_URL ||
    'http://localhost:3000/v1/graphql') as string;

  const httpLink = new HttpLink({ uri: graphqlUrl });

  const cache: InMemoryCache = new InMemoryCache({});
  const client: ApolloClient<NormalizedCacheObject> = new ApolloClient({
    cache,
    link: ApolloLink.from([errorLink, authLink, httpLink]),
  });

  return <ApolloProvider client={client}>{children}
    <ToastContainer/>
  </ApolloProvider>;
};

export default AuthorizedApolloProvider;
