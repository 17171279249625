import { useLocation, useSearchParams } from 'react-router-dom';
import { Loading } from '../components/Loading';
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { capitalize } from "../lib/utils";

export default function Connected() {

  const [searchParams] = useSearchParams();

  const [message, setMessage] = useState<{ state: string, message: string }>();

  useEffect(() => {
    const service = searchParams.get('service') ?? ''

    if (searchParams.get('success') === 'true') {
      setMessage({ state: 'Success', message: capitalize(service) + ' successfully connected.' });
    }

    if (searchParams.get('success') === 'false') {
      setMessage({ state: 'Error', message: `Failed connecting to ${capitalize(service)}. Please try again.` });
    }
  }, [searchParams]);


  return (
    <Loading active={!message}>
      <div className={'w-full flex justify-center'}>
        <div className="relative flex flex-col w-2/3 mt-4 border border-neutral-100 shadow-sm rounded-lg bg-white py-4">
          <span
            className={"text-2xl font-medium " + (message?.state === 'Success' ? 'text-green-600' : 'text-red-600')}>{message?.state}</span>
          <span>{message?.message}<br/> You now can <a href={"#"} className={"underline"}
                                                       onClick={() => window.close()}>close</a> the window.</span>
        </div>
      </div>
    </Loading>
  );
}
