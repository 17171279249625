import { Button, IconInfo, IconLink, IconPlus, IconShield, IconTrash } from "@supabase/ui";
import { Input } from '@supabase/ui';
import React, { useEffect, useState } from 'react';
import {
  GetSourcesDocument, useGetSourcesQuery,
  useGetSourceTypesQuery,
} from '../graphql/__generated__/queries';
import { useCreateSourceMutation, useDeleteSourceMutation } from '../graphql/__generated__/mutations';
import useSubmit from '../hooks/useSubmit';
import { useNavigate, useParams } from 'react-router-dom';
import { Loading } from '../components/Loading';
import SourceTypeForm from '../components/SourceTypeForm';
import { Postgresql, Salesforce, Snowflake } from "@icons-pack/react-simple-icons";
import { getSourceIcon, isAddon } from "../lib/utils";

export default function CreateSourceOfType() {
  const [mutation] = useCreateSourceMutation();

  const { type } = useParams<{ type: string }>();

  const { data, loading } = useGetSourceTypesQuery();

  const { data: existingData, loading: existingLoading } = useGetSourcesQuery();
  const [deleteMutation, { loading: deleteLoading }] = useDeleteSourceMutation();

  const nameToIcon = {
    'postgresql': Postgresql,
    'snowflake': Snowflake,
    'Salesforce': Salesforce
  };

  const [schemaType, setSchemaType] = useState<any>();

  const navigate = useNavigate();

  const [formVisible, setFormVisible] = useState(false)

  const handleSubmit = useSubmit(
    async ({ name, source_type_id, ...originalConfig }) => {
      const config: { [key: string]: any } = {};

      function getValue(value: string) {
        if (value === 'on') {
          return true;
        }

        if (value === 'off') {
          return false;
        }

        return value;
      }

      //FIXME: this is a hack to get around root_ prefix
      Object.keys(originalConfig).forEach((key) => {
        config[key.replace('root_', '')] = getValue(originalConfig[key]);
      });

      const { data } = await mutation({
        variables: {
          input: {
            name,
            source_type_id: schemaType?.id,
            config,
          },
        },
        refetchQueries: [GetSourcesDocument],
      });

      navigate(`/addon/sources/${data?.insert_source_one?.id}/pushes/create`);
    }
  );

  useEffect(() => {
    if (type) {
      setSchemaType(data?.source_type.find((x) => {
        return x?.name?.toLowerCase() === type
      }));
    } else {
      setSchemaType(data?.source_type[0]);
    }
  }, [data, type]);


  function onDelete(e: any, id: string) {
    e.preventDefault();
    e.stopPropagation();
    deleteMutation({
      variables: {
        id: id
      }
    }).then(() => navigate(isAddon() ? '/' : '/addon'))
  }

  if (loading) {
    return <Loading active={loading}>Loading...</Loading>;
  }

  return (
    <>
      {!formVisible && !!existingData?.source?.length &&
          <>
              <div className={"text-xs text-neutral-600 pb-2 px-4 pt-4 text-left flex"}>
                  Select source
              </div>
              <div className={"flex-col px-2 w-full"}>
                {existingData?.source.map(item => {
                  const Icon = getSourceIcon(item)
                  return <div onClick={() => navigate(`/addon/sources/${item.id}/pushes/create`)}
                              className={"flex justify-between bg-neutral-100 hover:bg-neutral-200 hover:cursor-pointer rounded-2xl font-medium text-sm py-3.5 px-4 mx-2 mb-2"}>
                    <div className={"flex w-full"}>
                      <Icon size={20} className={"mr-2 text-brand-600 font-medium"}/>
                      {item.name}
                    </div>
                    <IconTrash size={"small"} type={"text"} className={"hover:text-brand-600"} onClick={(e: any) => onDelete(e, item.id)}></IconTrash>
                  </div>
                })}
                  <div onClick={() => setFormVisible(true)}
                       className={"flex bg-brand-500 hover:bg-brand-600 hover:cursor-pointer text-white rounded-2xl font-medium text-sm py-3.5 px-4 mx-2 mb-2"}>
                      <IconPlus size={20} className={"mr-2"}/> Connect new source
                  </div>
              </div>
          </>}
      {(formVisible || !existingData?.source?.length) &&
          <form onSubmit={handleSubmit} className={'block h-full overflow-auto'}>
              <div
                  className={'flex flex-col justify-center items-center h-full mb-20'}
              >

                  <div className={'w-full p-4 max-w-2xl h-full pb-20'}>
                      <div
                          className="flex text-left p-4 mb-4 bg-neutral-100 rounded-2xl text-neutral-600"
                          role="alert"
                      >
                          <IconInfo className={"w-8 h-8 mr-2 text-brand-500"}/>
                          <div className="text-xs">
                              Allow queries from PushSQL's IP address <b className={"text-brand-500"}>34.172.84.217</b>.
                          </div>
                      </div>
                      <Input
                          layout={'vertical'}
                          label={'Source name'}
                          name={'name'}
                          size={"small"}
                          required
                          className={'pb-5'}
                      />
                    {schemaType && <SourceTypeForm schema={schemaType.config_schema}/>}
                  </div>
              </div>
              <div className={"absolute p-4 py-3 border-t bg-white bottom-0 left-0 right-0"}>
                  <button
                      className={"flex justify-center w-full bg-brand-500 text-center hover:bg-brand-600 hover:cursor-pointer text-white rounded-2xl font-medium text-sm py-3.5 px-4"}>
                      <IconPlus size={20} className={"mr-2"}/> Connect
                  </button>
                  <div className={"flex text-xs text-left py-2 text-neutral-500"}>
                      <IconShield className={"h-8 w-8 mr-2"}/>
                      <div>We encrypt credentials and never store source data.</div>
                  </div>
              </div>
          </form>}
    </>
  );
}

