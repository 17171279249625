import React, { useEffect, useRef } from 'react';
import Editor, { EditorProps } from '@monaco-editor/react';
import { Button, IconPlay } from '@supabase/ui';
import { useQuerySourceMutation } from '../graphql/__generated__/mutations';
import QueryResultGrid from './QueryResultGrid';
import { Loading } from './Loading';

interface SQLEditorProps {
  source_id?: string;
  onQueryResult: (value: any) => void;
}

export default function SQLEditor({
  source_id,
  onQueryResult,
  ...props
}: EditorProps & SQLEditorProps) {
  const [queryMutation, { loading: queryLoading, data }] =
    useQuerySourceMutation();

  const editorRef = useRef<any>();

  useEffect(() => {
    if (data?.source_query) {
      onQueryResult(data.source_query);
    }
  }, [data, onQueryResult]);

  const handleClick = async (e: any) => {
    e.preventDefault();

    if (!editorRef.current?.getValue()) {
      return;
    }

    if (!source_id) {
      return;
    }

    await queryMutation({
      variables: {
        id: source_id,
        query: editorRef.current?.getValue() ?? '',
      },
    });
  };

  return (
    <>
      <div
        className={
          'h-48 bg-white relative border rounded-md focus-within:border-brand-600 border-neutral-200 shadow-sm py-3 mt-2'
        }
      >
        <Editor
          {...props}
          options={{
            minimap: { enabled: false },
            renderLineHighlight: 'none',
            lineNumbers: 'off',
            glyphMargin: false,
            overviewRulerBorder: false,
            lineDecorationsWidth: 0,
            lineNumbersMinChars: 0,
            scrollBeyondLastLine: false,
          }}
          onMount={(editor) => {
            editorRef.current = editor;
          }}
          language={'sql'}
        />
        <div className={'absolute right-2 bottom-2'}>
          <Button
            onClick={handleClick}
            loading={queryLoading}
            type={'outline'}
            size={'medium'}
            icon={<IconPlay />}
          >
            Run query
          </Button>
        </div>
      </div>
      <Loading active={queryLoading}>
        <div
          className={`transition ${
            data?.source_query === undefined ? 'h-0 border-0' : 'h-40'
          } mt-2 border overflow-hidden rounded-md border-neutral-200 transition-height ease-in-out`}
        >
          {data?.source_query && (
            <QueryResultGrid queryResult={data?.source_query} />
          )}
        </div>
      </Loading>
    </>
  );
}
