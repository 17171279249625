import React from 'react';

function getFormValues(form: HTMLFormElement) {
  const data = new FormData(form);
  const dictData = Object.fromEntries(data.entries());

  form.querySelectorAll('form').forEach((f) => {
    // @ts-ignore
    dictData[f.name] = getFormValues(f);
  });

  return dictData;
}

export default function useSubmit(fn: (data: any) => void) {
  return (event: React.SyntheticEvent<HTMLFormElement>) => {
    event.preventDefault();
    const values = getFormValues(event.currentTarget);
    return fn(values);
  };
}
